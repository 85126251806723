import axios from "axios";
import { API_URL_USER } from "../util/util";

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL_USER + "login", {
        email,
        password
      })
      .then(response => {
        if (response.data.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        }
        
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL_USER + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();