import React, { Fragment } from 'react'
import { CloudUpload } from '@mui/icons-material'
import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const FileUploadField = ({
    accept,
    imageButton = false,
    hoverLabel = 'application.fileIconHover',
    dropLabel = 'application.fileIconDrop',
    width = '100%',
    height = '100px',
    backgroundColor = '#fff',
    onChange,
    onDrop
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [labelText, setLabelText] = React.useState(t(hoverLabel))
    const [isDragOver, setIsDragOver] = React.useState(false)
    const [isMouseOver, setIsMouseOver] = React.useState(false)
    const stopDefaults = (e) => {
        e.stopPropagation()
        e.preventDefault()
    }
    const dragEvents = {
        onMouseEnter: () => {
            setIsMouseOver(true)
        },
        onMouseLeave: () => {
            setIsMouseOver(false)
        },
        onDragEnter: (e) => {
            stopDefaults(e)
            setIsDragOver(true)
            setLabelText(t(dropLabel))
        },
        onDragLeave: (e) => {
            stopDefaults(e)
            setIsDragOver(false)
            setLabelText(t(hoverLabel))
        },
        onDragOver: stopDefaults,
        onDrop: (e) => {
            stopDefaults(e)
            setLabelText(t(hoverLabel))
            setIsDragOver(false)
            onDrop(e)
        },
    }

    const handleChange = (event) => {
        onChange(event)
    }

    return (
        <>
            <input
                onChange={handleChange}
                accept={accept}
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
            />

            <label
                htmlFor="file-upload"
                {...dragEvents}
                style={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    display: 'flex',
                    '&:hover p,&:hover svg,& img': {
                        opacity: 1,
                    },
                    '&:hover img': {
                        opacity: 0.3,
                    },
                    '& img': {
                        opacity: isDragOver ? 0.3 : 1,
                    },
                    '& p, svg': {
                        opacity: isDragOver ? 1 : 0.3
                    },
                }}
            >
                <Box
                    width={width}
                    height={height}
                    bgcolor={backgroundColor}
                    sx={{ pointerEvents: 'none', marginTop: 5 }}
                >
                    {(!imageButton || isDragOver || isMouseOver) && (
                        <Fragment>
                                <CloudUpload fontSize="large" sx={{color: theme.palette.primary.text}}/>
                                <Typography sx={{color: theme.palette.primary.text}}>{labelText}</Typography>
                        </Fragment>
                    )}
                </Box>
            </label>
        </>
    )
}
