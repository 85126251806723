import { AppBar, Toolbar, useTheme } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid';

const Header = () => {
    const user = useSelector((state) => state.user);
    const theme = useTheme();

    return (
        <AppBar sx={{ backgroundColor: theme.palette.white, paddingRight: 2, paddingLeft: 2 }} elevation={3}>
            <Toolbar sx={{ height: theme.header.height }}>
                <Grid container item direction="row" justifyContent="flex-start" alignItems="center" >
                    <Grid item sx={{ paddingLeft: 31 }}>
                        <img alt="Logo" src={user.logo} style={{ height: 50, marginRight: 14 }} />
                    </Grid>
                    <Grid item sx={{ color: theme.palette.primary.text, fontWeight: 600, fontSize: 22, marginTop: "-3px", display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>{user.company.companyName}</Grid>
                    <Grid item></Grid>
                </Grid>
                <Grid item sx={{ minWidth: 200, textAlign: "right" }}>
                    <div style={{ color: theme.palette.primary.main, fontWeight: "600", fontSize: 20 }}>Hi, {user.firstName}</div>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export default Header