import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


function DatePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={props.title}
          inputFormat="dd/MM/yyyy"
          value={props.value}
          onChange={props.handleChange}
          renderInput={(text) => <TextField {...text} />}
        />
    </LocalizationProvider>
  )
}

export default DatePicker