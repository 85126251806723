import React from 'react'
import { styled, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import { INFO_TYPE } from '../../util/util';

const InfoCardComponent = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.white,
    ...theme.typography.body2,
    padding: 16,
    borderRadius: 16,
    textAlign: 'center',
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 200,
    margin: 5,
    height: 120
}));

const InfoCard = (props) => {
    const theme = useTheme();

    let color = theme.palette.primary.main;
    if (props.type !== undefined) {
        if (props.type  === INFO_TYPE.DANGER) {
            color = theme.palette.red[400];
        }
        else if (props.type  === INFO_TYPE.WARNING) {
            color = theme.palette.orange[400];
        }
        else if (props.type  === INFO_TYPE.HEALTH) {
            color = theme.palette.green[400];
        }
    }

    let oppColor = theme.palette.white;
    
    return (
        <InfoCardComponent sx={{backgroundColor: props.isColor?color:oppColor}}>
            <Grid container direction="column" justifyContent="space-around" alignItems="flex-start" >
                <Grid item container direction="row" justifyContent="space-between" alignItems="center" sx={{ marginTop: "-6px" }}>
                    <Grid item>
                        <props.Icon sx={{ fontSize: 38, color: props.isColor?oppColor:color }} />
                    </Grid>
                    {/*
                <Grid item>
                    <MoreVertIcon sx={{fontSize: 36, marginRight: "-6px"}} />
                </Grid>
  **/}
                </Grid>
                <Grid item sx={{ fontSize: 24, fontWeight: 600, marginTop: "1px", marginBottom: "3px", marginLeft: "5px", color: props.isColor?oppColor:color }} >
                    {props.value}
                </Grid>
                <Grid item sx={{ fontSize: 20, marginLeft: "4px", marginBottom: "-14px", color: props.isColor?oppColor:color}}>
                    {props.desc}
                </Grid>
            </Grid>
        </InfoCardComponent>
    )
}

export default InfoCard