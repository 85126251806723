import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box';
import LayoutNoTitle from '../../components/core/Layout';
import PageHeader from '../../components/header/PageHeader';
import EnhancedEmploymentTable from '../../components/table/EmploymentTable';
import { getEmploymentPerProject, getEmployments } from '../../redux/features/worker/workerSlice';

const Employment = () => {
    const dispatch = useDispatch();
    const selectedProject = useSelector((state) => state.project.selected);

    useEffect(() => {
        console.log("Employment selectedProject: " + selectedProject);
        dispatch(selectedProject===""?getEmployments():getEmploymentPerProject({ pid: selectedProject }));
    }, [selectedProject]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <LayoutNoTitle>
            <Box sx={{pr: 4, pl: 4}}>
                <PageHeader isLess={true} handleChange={() => {}} />
                <EnhancedEmploymentTable />
            </Box>
        </LayoutNoTitle>
    )
}

export default Employment
