import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { MyOutlinedButton } from '../button/Button';
import { EnhancedTableHead, getComparator, stableSort, StyledInnerTableCell, StyledTableCell, StyledTableRow } from './EnhancedTable';
import { useDispatch, useSelector } from 'react-redux';
import { formatServerDateTime } from '../../util/util';
import { CircularProgress, Collapse, IconButton, TableHead, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { getPayrollHistoryByID } from '../../redux/features/worker/workerSlice';
import { MyBackdrop } from '../backdrop/Backdrop';

const EnhancedTableToolbar = (props) => {
    const { t } = useTranslation();

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
            }}
        >
                    <MyOutlinedButton name={t('common.btnRefresh')} onClick={() => { props.refreshData(); }} />
       
          
        </Toolbar>
    );
};

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const data = useSelector((state) => state.worker.payroll.history.data);
    const timezone = useSelector((state) => state.user.timezone);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <StyledTableRow key={row._id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {if(!open) { dispatch(getPayrollHistoryByID({payloadID: row._id}));} setOpen(!open); }}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell align="left">{formatServerDateTime(row.createdAt, timezone)}</StyledTableCell>
                <StyledTableCell align="left">{row.createdBy.firstName + t('common.space') + row.createdBy.lastName}</StyledTableCell>
                <StyledTableCell align="left">{row.status}</StyledTableCell>
                <StyledTableCell align="left">{row.totalAmount}</StyledTableCell>
                <StyledTableCell align="left">{row.totalWorker}</StyledTableCell>
                <StyledTableCell align="left">{row.totalWorkingDays}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h7" gutterBottom component="div">
                                Details
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledInnerTableCell>{t("worker.name")}</StyledInnerTableCell>
                                        <StyledInnerTableCell>{t("worker.hkid")}</StyledInnerTableCell>
                                        <StyledInnerTableCell>{t("worker.bank")}</StyledInnerTableCell>
                                        <StyledInnerTableCell>{t("worker.bankAccount")}</StyledInnerTableCell>
                                        <StyledInnerTableCell>{t("payroll.totalSalary")}</StyledInnerTableCell>
                                        <StyledInnerTableCell>{t("payroll.totalWorkingDays")}</StyledInnerTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                {data[row._id]?.map((workerRow) => (
                                    <StyledTableRow key={workerRow.hkid}>
                                        <StyledInnerTableCell align="left">{workerRow.name}</StyledInnerTableCell>
                                        <StyledInnerTableCell align="left">{workerRow.hkid}</StyledInnerTableCell>
                                        <StyledInnerTableCell align="left">{workerRow.bank}</StyledInnerTableCell>
                                        <StyledInnerTableCell align="left">{workerRow.bankAccount}</StyledInnerTableCell>
                                        <StyledInnerTableCell align="left">{workerRow.totalSalary}</StyledInnerTableCell>
                                        <StyledInnerTableCell align="left">{workerRow.totalWorkingDays}</StyledInnerTableCell>
                                    </StyledTableRow>
                                ))}
                                </TableBody>
                            </Table>                        
                        </Box>
                    </Collapse>
                </StyledTableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}


export default function EnhancedPayrollHistoryTable(props) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { t } = useTranslation();
    const data = useSelector((state) => state.worker.payroll.history.list);
    const isLoading = useSelector((state) => state.worker.payroll.history.isLoading);

    const headCells = [
        {
            id: 'icon',
            align: 'left',
            disablePadding: false,
            label: "",
        },
        {
            id: 'createDate',
            align: 'left',
            disablePadding: false,
            label: t("common.createDate"),
        },
        {
            id: 'createBy',
            align: 'left',
            disablePadding: false,
            label: t("common.createBy"),
        },
        {
            id: 'status',
            align: 'left',
            disablePadding: false,
            label: t("common.status"),
        },
        {
            id: 'totalAmount',
            align: 'left',
            disablePadding: false,
            label: t("payroll.totalAmount"),
        },
        {
            id: 'totalWorker',
            align: 'left',
            disablePadding: false,
            label: t("payroll.totalWorker"),
        },
        {
            id: 'totalWorkingDays',
            align: 'left',
            disablePadding: false,
            label: t("payroll.totalWorkingDays"),
        },
    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar refreshData={props.refreshData} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(data, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <Row key={row._id} row={row} />
                                    );
                                })}                            
                            {isLoading?<MyBackdrop open={isLoading}><CircularProgress color="inherit" /></MyBackdrop> : data.length === 0?<StyledTableRow>
                                        <StyledTableCell colSpan={9} align="center">{t('common.noTableData')}</StyledTableCell></StyledTableRow>:null}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
