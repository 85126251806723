import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { MyButton, MyOutlinedButton } from '../button/Button';
import { useNavigate } from "react-router-dom";
import { CircularProgress, Toolbar } from '@mui/material';
import { EnhancedTableHead, getComparator, stableSort, StyledTableCell, StyledTableRow } from './EnhancedTable';
import { COMPANY_TYPE, formatDate, numberToCurrency } from '../../util/util';
import { StatusCircle } from '../statusCircle/StatusCircle';
import { useSelector } from 'react-redux';
import { MyBackdrop } from '../backdrop/Backdrop';

const EnhancedTableToolbar = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();  

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end"
            }}
        >   
            <MyOutlinedButton name={t('projectNewButton')} onClick={()=>{navigate("/project/create")}} />
            {/*
            <Tooltip title="Filter list">
                <IconButton>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
        */}
        </Toolbar>
    );
};

export const EnhancedTable = (props) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const companyType = useSelector(state => state.user.company.type);
    const isLoading = useSelector((state) => state.project.isLoading);

    let rows = props.data;

    const headCells = [
        {
            id: 'name',
            align: 'left',
            disablePadding: false,
            label: t("projectTableItem.project"),
        },
        {
            id: 'Status',
            align: 'center',
            disablePadding: false,
            label: t("projectTableItem.status"),
        },
        {
            id: 'projectManager',
            align: 'center',
            disablePadding: false,
            label: t("projectTableItem.manager"),
        },
        {
            id: 'projectDate',
            align: 'center',
            disablePadding: false,
            label: t("projectTableItem.date"),
        },
        {
            id: 'price',
            align: 'right',
            disablePadding: false,
            label: t("projectTableItem.price"),
        },
        {
            id: 'action',
            align: 'center',
            disablePadding: false,
            label: t("projectTableItem.action"),
        },
    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
            {companyType === COMPANY_TYPE.DEVELOPER?
                <EnhancedTableToolbar />:null}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {

                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <StyledTableRow
                                            hover
                                            tabIndex={-1}
                                            key={row._id}
                                        >
                                            <StyledTableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="normal"
                                            >
                                                {row.name}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center" }}>
                                                    <StatusCircle value={row.status.danger} color={theme.palette.red[400]} background={theme.palette.white}/>
                                                    <StatusCircle value={row.status.warning} color={theme.palette.orange[400]} background={theme.palette.white}/>
                                                    <StatusCircle value={row.status.normal} color={theme.palette.green[400]} background={theme.palette.white}/>
                                                </Box>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{row.manager}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {formatDate(row.startDate)} {t("common.to")} {formatDate(row.endDate)}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{numberToCurrency(row.price)}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <MyButton name={t('common.btnView')} onClick={()=>{navigate("/project/chart", {state: {pid: row._id}});}}></MyButton>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            
                            {isLoading?<MyBackdrop open={isLoading}><CircularProgress color="inherit" /></MyBackdrop> : rows.length === 0?<StyledTableRow>
                                        <StyledTableCell colSpan={9} align="center">{t('common.noTableData')}</StyledTableCell></StyledTableRow>:null}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
