import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/header/PageHeader';
import { getPayrollHistory } from '../../redux/features/worker/workerSlice';
import EnhancedPayrollHistoryTable from '../../components/table/PayrollHistoryTable';
import LayoutWithBackButton from '../../components/core/LayoutWithBackButton';

const PayrollHistory = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectedProject = useSelector((state) => state.project.selected);

    let refreshData = () => {
        console.log("Payroll selectedProject: " + selectedProject);
        if (selectedProject!=="") {
            dispatch(getPayrollHistory({ pid: selectedProject}));
        }
    };

    useEffect(() => {
        refreshData();
    }, [selectedProject]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <LayoutWithBackButton title={t('page.payrollHistory')}>
            <Box sx={{p: 4, pb: 1, mr: 2, mt: -10 }}>
                <PageHeader isLess={true} handleChange={() => {}} />
                <EnhancedPayrollHistoryTable refreshData={refreshData}/>
            </Box>
        </LayoutWithBackButton>
    )
}

export default PayrollHistory
