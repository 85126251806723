import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ListIcon from '@mui/icons-material/List';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import TimelineIcon from '@mui/icons-material/Timeline';
import EmailIcon from '@mui/icons-material/Email';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { COMPANY_TYPE } from '../../../util/util';
import { Badge, Engineering, EventAvailable, FileCopy, LibraryBooks, Paid, Person } from '@mui/icons-material';

export const wokerSidebarItems = [
    {
        id: 51,
        icon: <Badge />,
        label: 'Employment',
        route: '/worker/employment',
        isSubMenu: true,
        companytype: [COMPANY_TYPE.DEVELOPER, COMPANY_TYPE.CONTRACTOR]
    },
    {
        id: 52,
        icon: <EventAvailable />,
        label: 'Attendance',
        route: '/worker/attendance',
        isSubMenu: true,
        companytype: [COMPANY_TYPE.DEVELOPER, COMPANY_TYPE.CONTRACTOR]
    },
    {
        id: 53,
        icon: <Paid />,
        label: 'Payroll',
        route: '/worker/payroll',
        isSubMenu: true,
        companytype: [COMPANY_TYPE.DEVELOPER, COMPANY_TYPE.CONTRACTOR]
    }
]

export const mainSidebarItems = [
    {
        id: 0,
        icon: <ViewModuleIcon />,
        label: 'Overview',
        route: '/home',
        companytype: [COMPANY_TYPE.DEVELOPER, COMPANY_TYPE.CONTRACTOR]
    },
    {
        id: 1,
        icon: <ListIcon />,
        label: 'Project',
        route: '/project',
        companytype: [COMPANY_TYPE.DEVELOPER, COMPANY_TYPE.CONTRACTOR]
    },
    {
        id: 2,
        icon: <PriceChangeIcon />,
        label: 'Cash Flow',
        route: '/cashflow',
        companytype: [COMPANY_TYPE.DEVELOPER, COMPANY_TYPE.CONTRACTOR]
    },
    {
        id: 3,
        icon: <LibraryBooks />,
        label: 'Application',
        route: '/application',
        companytype: [COMPANY_TYPE.DEVELOPER, COMPANY_TYPE.CONTRACTOR]
    },
    {
        id: 4,
        icon: <TimelineIcon />,
        label: 'Invitation',
        route: '/invitation',
        companytype: [COMPANY_TYPE.DEVELOPER, COMPANY_TYPE.CONTRACTOR]
    },
    {
        id: 5,
        icon: <Engineering />,
        label: 'Worker',
        route: '/worker',
        subMenu: wokerSidebarItems,
        companytype: [COMPANY_TYPE.DEVELOPER, COMPANY_TYPE.CONTRACTOR]
    }
];


export const settingSidebarItems = [
    {
        id: 1011,
        icon: <Person />,
        label: 'Profile',
        route: '/setting/profile',
        isSubMenu: true,
        companytype: [COMPANY_TYPE.DEVELOPER, COMPANY_TYPE.CONTRACTOR]
    },
    {
        id: 1012,
        icon: <FileCopy />,
        label: 'Sample File',
        route: '/setting/sample',
        isSubMenu: true,
        companytype: [COMPANY_TYPE.DEVELOPER, COMPANY_TYPE.CONTRACTOR]
    }
];


export const subSidebarItems = [
    {
        id: 100,
        icon: <EmailIcon />,
        label: 'Messages',
        route: '/messages',
        companytype: [COMPANY_TYPE.DEVELOPER, COMPANY_TYPE.CONTRACTOR]
    },
    {
        id: 101,
        icon: <SettingsIcon />,
        label: 'Setting',
        route: '/setting',
        subMenu: settingSidebarItems,
        companytype: [COMPANY_TYPE.DEVELOPER, COMPANY_TYPE.CONTRACTOR]
    },
    {
        id: 102,
        icon: <LogoutIcon />,
        label: 'Logout',
        route: '/login',
        companytype: [COMPANY_TYPE.DEVELOPER, COMPANY_TYPE.CONTRACTOR]
    },
]