import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

function FormInput(props) {
  return (
    <FormControl 
      sx={{m:2, ml: -0.1}} 
      margin={'dense'}
      size={"normal"}
      fullWidth={props.fullWidth}>
      <InputLabel htmlFor="input-value"
      >{props.title}
      </InputLabel>
      <OutlinedInput
        id="input-value"
        value={props.value}
        onChange={props.handleChange}
        label={props.title}
        onFocus={props.onFocus}
        readOnly={props.readOnly}
      />
  </FormControl>
  )
}

export default FormInput