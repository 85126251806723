import React, { useEffect } from 'react'
import Navbar from '../../components/navbar/Navbar'
import Box from '@mui/material/Box';
import Header from '../../components/header/Header';
import InfoCard from '../../components/informationcard/InfoCard';
import AccountTreeOutlined from '@mui/icons-material/AccountTreeOutlined';
import { Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EnhancedTable } from '../../components/table/ProjectTable';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects } from '../../redux/features/project/projectSlice';
import { numberToCurrency } from '../../util/util';
import AttachMoney from '@mui/icons-material/AttachMoney';

const StatusSign = (props) => {
  const theme = useTheme();
  return (
    <Grid container sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center", width: 160 }}>
      <Grid item sx={{ borderWidth: 1, borderColor: props.color[400], borderStyle: 'solid', width: 120, borderRadius: 15, display: "flex", justifyContent: "center", alignItem: "center", background: theme.palette.white }}>
        <div style={{ color: props.color[400], lineHeight: "32px" }}>
          {props.title}
        </div>
      </Grid>
      <Grid item sx={{ borderRadius: 15, background: props.color[400], width: 32, height: 32, display: "flex", justifyContent: "center", alignItem: "center" }}>
        <div style={{ color: theme.palette.white, lineHeight: "32px" }}>
          {props.value}
        </div>
      </Grid>
    </Grid>
  )
}

export const Project = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.project.data);
  const status = useSelector((state) => state.project.status);
  const totalAmount = useSelector((state) => state.project.totalAmount);

  useEffect(() => {
    dispatch(getProjects());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Box sx={{ display: 'flex' }}>
      <Navbar />
      <Header />
      <Box
        component="main"
        sx={{ flexGrow: 1, mt: 16, p: 3, pt: 0 }}>
        <div className='cardContainer'>
          <InfoCard value={data.length} desc={t('project.totalProjects')} Icon={AccountTreeOutlined} />
          <InfoCard value={numberToCurrency(totalAmount)} desc={t('project.totalAmount')} Icon={AttachMoney} />
          <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: 120, width: 200, padding: "16px", borderRadius: 4, boxShadow: 2, margin: "5px" }}> 
            <StatusSign title={t('status.danger')} color={theme.palette.red} value={status.danger} />
            <StatusSign title={t('status.warning')} color={theme.palette.orange} value={status.warning} />
            <StatusSign title={t('status.normal')} color={theme.palette.green} value={status.normal} />
          </Box>
        </div>
        <EnhancedTable data={data}/>
      </Box>
    </Box>
  )
}
