import React, { Fragment, useEffect } from 'react';
import Box from '@mui/material/Box';
import PageHeader from '../../components/header/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LayoutNoTitle from '../../components/core/Layout';
import EnhancedCashflowTable from '../../components/table/CashflowTable';
import { getCashflow } from '../../redux/features/project/projectSlice';
import { MyBackdrop } from '../../components/backdrop/Backdrop';
import { CircularProgress } from '@mui/material';

export const Cashflow = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectedProject = useSelector((state) => state.project.selected);
    const isLoading = useSelector((state) => state.project.isLoading);

    useEffect(() => {
        if (selectedProject !== "") {
            console.log("Cashflow selectedProject: " + selectedProject);
            dispatch(getCashflow({ pid: selectedProject }));
        }
    }, [selectedProject]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <LayoutNoTitle title={t('page.projectTree')}>            
            <Box sx={{pr: 4, pl: 4}}>
            {isLoading?
                <MyBackdrop
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </MyBackdrop> :
                <Fragment>
                    <PageHeader handleChange={(id)=>{dispatch(getCashflow({ pid: id }))}} />
                    <EnhancedCashflowTable refreshData={()=>{
                        if (selectedProject !== "") {
                            console.log("Cashflow selectedProject: " + selectedProject);
                            dispatch(getCashflow({ pid: selectedProject }));
                        }
                    }}/>
                </Fragment>
            }
            </Box>
        </LayoutNoTitle>
    )
}
