import * as React from 'react';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: theme.palette.primary.transparentBg,
  color: theme.palette.primary.main, 
  zIndex: (theme) => theme.zIndex.drawer + 1, 
  position: "absolute"
}));

export const MyBackdrop = (props) => {
    return <StyledBackdrop {...props}>{props.children}</StyledBackdrop>
}