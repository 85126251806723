import { Box, Stack, Typography, useTheme } from '@mui/material'
import React, {  useState } from 'react'
import FormInput from '../../components/input/FormInput';
import DatePicker from '../../components/datePicker/DatePicker';
import { useTranslation } from 'react-i18next';

import "./projectCreation.scss";
import { MyBigButton } from '../../components/button/Button';
import LayoutWithBackButton from '../../components/core/LayoutWithBackButton';
import userService from '../../services/user.service';
import AlertDialogSlide from '../../components/dialog/AlertDialogSlide';
import { useNavigate } from 'react-router-dom';

export const ProjectCreation = () => {

    const [formData, setFormData] = useState({
        projectTitle: "",
        desc: "",
        siteAddress: "",
        startDate: "",
        endDate: "",
        initialFund: "",
        projectManager: "",
        approver: "",
        quantitySurveyor: ""
    });
    const [open, setOpen] = useState(false);

    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const handleChange = (event, title) => {
        event.preventDefault();
        setFormData({ ...formData, [title]: event.target.value });
    };

    const handleEndDateChange = (value) => {
        setFormData({ ...formData, "endDate": value })
    };

    const handleStartDateChange = (value) => {
        setFormData({ ...formData, "startDate": value })
    };

    const handleOnSubmit = () => {
        console.log(formData);
        userService.createProject(formData).then((result) => {
            if (result.status === 200) {
                setOpen(true);
            }
            else {
                //TODO error page
            }
        });
    }

    return (
        <LayoutWithBackButton title={t('projectNewButton')}>
            <Box sx={{ p: 4, pb: 1, mr: 2, mt: -6 }}>
                <FormInput title={t("project.projectTitle")} fullWidth={true} value={formData.projectTitle} handleChange={(e) => handleChange(e, "projectTitle")} />
                <FormInput title={t("project.desc")} fullWidth={true} value={formData.desc} handleChange={(e) => handleChange(e, "desc")} />
                <FormInput title={t("project.siteAddress")} fullWidth={true} value={formData.siteAddress} handleChange={(e) => handleChange(e, "siteAddress")} />

                <Stack direction={"row"} spacing={3} sx={{ my: 1 }} alignItems="center" >
                    <DatePicker handleChange={handleStartDateChange} inputFormat="yyyy-MM-dd" value={formData.startDate} title={t("project.startDate")} />
                    <Typography color={theme.palette.gray[800]}>
                        {t('common.to')}
                    </Typography>
                    <DatePicker handleChange={handleEndDateChange} inputFormat="yyyy-MM-dd" value={formData.endDate} title={t("project.endDate")} />
                </Stack>

                <FormInput title={t("project.initialFund")} fullWidth={true} inputAdornment={"$"} value={formData.initialFund} handleChange={(e) => handleChange(e, "initialFund")} />
                <FormInput title={t("project.projectManager")} fullWidth={true} value={formData.projectManager} handleChange={(e) => handleChange(e, "projectManager")} />
                <FormInput title={t("project.approver")} fullWidth={true} value={formData.approver} handleChange={(e) => handleChange(e, "approver")} />
                <FormInput title={t("project.quantitySurveyor")} fullWidth={true} value={formData.quantitySurveyor} handleChange={(e) => handleChange(e, "quantitySurveyor")} />
            </Box>

            <Stack direction={"row"} justifyContent="center" alignItems="center">
                <MyBigButton variant='contained' sx={{ paddingX: 10, mb: 4 }} onClick={handleOnSubmit} name={t("project.submitBtn")} />
            </Stack>
            <AlertDialogSlide isOpen={open} title={t('page.projectCreation')} text={t('project.dialogCreateProject')} handleOKClose={()=> {navigate("/project")}}/>
        </LayoutWithBackButton>
    )
}
