import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { mainSidebarItems, subSidebarItems } from './const/navbarListItems'
import { SidebarStyles } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Collapse, ListItemButton } from '@mui/material';
import { ExpandLess, ExpandMore  } from '@mui/icons-material';

const MenuListItemButton = styled(ListItemButton)(({ theme }) => ({
    "&.Mui-selected:hover": {
        backgroundColor: theme.palette.primary.text,
    },
    "&:hover": {
        backgroundColor: theme.palette.primary.text,
        color: "white",
        "& .MuiListItemButton-root": {
            color: "white"
        }
    },
}));

const MenuItem = (props) => {
    const { pathname, item, navigate, dispatch, userCompanyType, indentation } = props;
    const [open, setOpen] = React.useState(isSelectedSubMenuItem(pathname, item.route));
    const theme = useTheme();
    let selected = isSelectedMenuItem(pathname, item.route, item.subMenu) || open;
    
    return (
        <React.Fragment>
            <MenuListItemButton
                key={item.id.toString()}
                sx={{ pl: indentation+2, backgroundColor: item.isSubMenu?theme.palette.primary.subMenu.bg:theme.palette.primary.main,
                '&.Mui-selected': {
                    backgroundColor: item.isSubMenu?theme.palette.primary.subMenu.selectedBg:theme.palette.primary.text,
                },
                paddingTop: item.isSubMenu?"5px":"8px",
                paddingBottom: item.isSubMenu?"5px":"8px"
                }}
                onClick={() => {
                    if (item.subMenu === undefined) {
                        if (item.label === "Logout") {
                            dispatch({ type: "USER_LOGOUT" })
                        }
                        navigate(item.route);
                    }
                    else {
                        setOpen(!open);
                    }
                }}
                selected={selected}
            >
                <ListItemIcon
                    sx={SidebarStyles.icons}
                >
                    {item.icon}
                </ListItemIcon>
                <ListItemText
                    sx={{'& span': {
                        marginLeft: '-20px',
                        fontSize: item.isSubMenu?'14px':'16px',
                    }}}
                    primary={item.label}
                />
                {item.subMenu !== undefined ? open ? <ExpandLess /> : <ExpandMore /> : null}
            </MenuListItemButton>
            {item.subMenu !== undefined?
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {item.subMenu.map((itemInner, i) => (
                        isShow(userCompanyType, itemInner.companytype) ?
                            <MenuItem key={i} item={itemInner} pathname={pathname} dispatch={dispatch} navigate={navigate} indentation={indentation+4}/> : null
                    ))}
                </List>
            </Collapse>:null}
        </React.Fragment>
    );
}

const isSelectedMenuItem = (pathname, route, isSubMenu) => {
    if (pathname === "/") {
        if (route === "/project") {
            return true;
        }
    }
    else if (pathname.startsWith(route) && !isSubMenu) {
        return true;
    }
    return false;
}

const isSelectedSubMenuItem = (pathname, route) => {
    if (pathname.startsWith(route)) {
        return true;
    }
    return false;
}


const isShow = (userComanyType, allowedCompanyType) => {
    if (userComanyType === undefined) {
        return false;
    }
    return allowedCompanyType.includes(userComanyType);
}

const Navbar = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const userCompanyType = useSelector((state) => state.user.company.type);
    return (
        <Drawer
            sx={{
                width: 260,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 260,
                    boxSizing: 'border-box',
                    backgroundColor: theme.palette.primary.main,
                    color: '#ffffff'
                }
            }}
            variant="permanent"
            anchor="left"
        >
            <Toolbar sx={{ height: theme.header.height }}>
                <img alt="Logo" src="/images/logo.svg" style={{ height: 35 }} />
            </Toolbar>
            <Divider />
            <List>
                {mainSidebarItems.map((item, i) => (
                    isShow(userCompanyType, item.companytype) ?
                        <MenuItem key={i} item={item} pathname={pathname} dispatch={dispatch} navigate={navigate} userCompanyType={userCompanyType} indentation={0}/> : null
                ))}
            </List>
            <Divider />
            <List>
                {subSidebarItems.map((item, i) => (
                    isShow(userCompanyType, item.companytype) ?
                        <MenuItem key={i} item={item} pathname={pathname} dispatch={dispatch} navigate={navigate} userCompanyType={userCompanyType} indentation={0}/> : null
                ))}
            </List>
        </Drawer>
    )
}

export default Navbar