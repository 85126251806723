import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AuthService from '../../../services/auth.service'

const INITIAL_STATE = {
  id: "",
  token: "",
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  logo: '/images/companyLogo.png',
  userName: "",
  timezone: {
    name: "Asia/Taipei",
    value: "+08:00"
  },
  isFetching: false,
  isSuccess: false,
  isError: false,
  roles: [],
  company: {}
}

export const loginUser = createAsyncThunk(
  "users/login",
  async ({ email, password }, thunkAPI) => {
    console.log("createAsyncThunk email: " + email + " | password: " + password);
    try {
      /*
      if (email == "developer@defypay.com" && password == "password") {
        console.log("enter developer profile");
        return DEVELOPER_PROEILE;
      }
      else if (email == "contractor@defypay.com" && password == "password") {
        return CONTRACTOR_PROFIFLE;
      }
      else if (email == "admin@defypay.com" && password == "password") {
        return ADMIN_PROFILE;
      }
      */
      let response = await AuthService.login(email, password)
        .catch(function (error) {
          console.log(error);
          return thunkAPI.rejectWithValue("INVALID_LOGIN");
        })
      return response;

    } catch (e) {
      console.log("Error", e.response.data)
      thunkAPI.rejectWithValue(e.response.data)
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state = {
        ...INITIAL_STATE,
        ...action.payload,
        isFetching: true,
      }
    })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.userName = action.payload.data.user.firstName;
        state.firstName = action.payload.data.user.firstName;
        state.lastName = action.payload.data.user.lastName;
        state.email = action.payload.data.user.email;
        state.roles = action.payload.data.user.roles;
        state.company = action.payload.data.user.company;
        state.timezone = action.payload.data.user.timezone;
        state.isError = false;
        state.isSuccess = true;
        state.isFetching = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
      })
  },
})

export const { login, logout } = userSlice.actions

export default userSlice.reducer
export const userSelector = state => state.user