import React from 'react'
import "./unauthorized.scss";
import { styled, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { MyBigButton } from '../../components/button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#f0f8ff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Banner = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    ...theme.typography.body2,
    padding: { sm: 50 },
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const Unauthorized = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Grid container>
            <Grid item xs={0} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                <Banner>
                    <Grid item xs={12} sx={{ color: 'white', fontSize: '30px' }}>
                        DefyPay
                    </Grid>
                </Banner>
            </Grid>
            <Grid item xs={12} md={6}>
                <Item>
                    <Grid container direction="column" spacing={1}>
                        <Grid item xs={12} sx={{ fontSize: 32, color: theme.palette.primary.main, fontWeight: '600', marginBottom: 0, marginTop: -5 }}>{t('unauthorized.title')}</Grid>
                        <Grid item xs={12}>
                            <MyBigButton sx={{marginTop: 1}} name={t('unauthorized.backBtn')} onClick={() => {navigate('/login')}}/>
                        </Grid>
                    </Grid>
                </Item>
            </Grid>
        </Grid>
    )
}
