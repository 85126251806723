import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { MyButton, MyOutlinedButton } from '../button/Button';
import { useNavigate } from "react-router-dom";
import { EnhancedTableHead, getComparator, stableSort, StyledTableCell, StyledTableRow } from './EnhancedTable';
import { useDispatch, useSelector } from 'react-redux';
import { formatServerDateTime, APPLICATION_STATUS, handleDownloadFile, COMPANY_TYPE } from '../../util/util';
import AlertDialogSlide from '../dialog/AlertDialogSlide';
import userService from '../../services/user.service';
import { getApplications } from '../../redux/features/application/applicationSlice';
import CreateApplicationAlertDialogSlide from '../dialog/CreateApplicationAlertDialogSlide';
import { MyBackdrop } from '../backdrop/Backdrop';
import { CircularProgress } from '@mui/material';

const EnhancedTableToolbar = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const companyType = useSelector(state => state.user.company.type);

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end"
            }}
        >
            <MyOutlinedButton sx={{marginRight: "10px"}} name={t('common.btnRefresh')} onClick={()=>{props.dispatch(getApplications())}} />            
        {companyType === COMPANY_TYPE.DEVELOPER?null:<MyOutlinedButton name={t('application.newApplicationBtn')} onClick={()=>{navigate("/application/create")}} />}
        </Toolbar>
    );
};


export default function EnhancedApplicationTable() {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.application.list);
    const user = useSelector((state) => state.user);
    const [open, setOpen] = React.useState(false);
    const [openResult, setOpenResult] = React.useState(false);
    const [openAccept, setOpenAccept] = React.useState(false);
    const [progress, setProgress] = React.useState("");
    const [selectedProject, setSelectedProject] = React.useState({aid: "", status: "", progress: ""});
    const isLoading = useSelector((state) => state.application.isLoading);

    const headCells = [
        {
            id: 'createdAt',
            align: 'center',
            disablePadding: false,
            label: t("application.date"),
        },
        {
            id: 'project',
            align: 'center',
            disablePadding: false,
            label: t("application.project"),
        },
        {
            id: 'companyRequest',
            align: 'center',
            disablePadding: false,
            label: t("application.companyRequest"),
        },
        {
            id: 'company',
            align: 'center',
            disablePadding: false,
            label: t("application.company"),
        },
        {
            id: 'title',
            align: 'center',
            disablePadding: false,
            label: t("application.title"),
        },
        {
            id: 'type',
            align: 'center',
            disablePadding: false,
            label: t("application.type"),
        },
        {
            id: 'status',
            align: 'center',
            disablePadding: false,
            label: t("application.statusS"),
        },
        {
            id: 'file',
            align: 'center',
            disablePadding: false,
            label: t("application.file"),
        },
        {
            id: 'action',
            align: 'center',
            disablePadding: false,
            label: t("application.action"),
        },
    ];


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar dispatch={dispatch}/>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(data, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <StyledTableRow
                                            hover
                                            tabIndex={-1}
                                            key={row._id}
                                        >
                                            <StyledTableCell align="center">{formatServerDateTime(row.createdAt, user.timezone)}</StyledTableCell>
                                            <StyledTableCell align="center">{row.projectName}</StyledTableCell>
                                            <StyledTableCell align="center">{row.submittedToCompany}</StyledTableCell>
                                            <StyledTableCell align="center">{row.companyName}</StyledTableCell>
                                            <StyledTableCell align="center">{row.title}</StyledTableCell>
                                            <StyledTableCell align="center">{t('application.applicationType.'+row.type)}</StyledTableCell>
                                            <StyledTableCell align="center">{t(row.status===APPLICATION_STATUS.CREATED?user.company._id===row.company?'application.status.waitforReply':'application.status.pending':"application.status."+row.status)}</StyledTableCell>                                            
                                            <StyledTableCell align="center">
                                                <MyButton name={t('common.btnDownload')} onClick={()=> {
                                                    userService.downloadApplicationFile(row._id)
                                                    .then((response) => {
                                                        handleDownloadFile(response);
                                                    }).catch((error) => {
                                                        console.log(error);
                                                    });
                                                }} />
                                            </StyledTableCell>
                                            {row.status===APPLICATION_STATUS.CREATED?user.company._id===row.company?
                                            <StyledTableCell align="center">
                                                <MyButton name={t('common.btnWithdraw')} onClick={()=> {
                                                        setSelectedProject({aid: row._id, status: APPLICATION_STATUS.CANCELED});
                                                        setOpen(true);
                                                        }}/>
                                            </StyledTableCell>:
                                            <StyledTableCell align="center">
                                                <Box sx={{display: 'flex', flexDirection: "row", justifyContent: "center"}}>
                                                    <MyButton name={t('common.btnAccept')} onClick={()=> {
                                                        setSelectedProject({aid: row._id, status: APPLICATION_STATUS.ACCEPTED});
                                                        setProgress("");
                                                        setOpenAccept(true);
                                                        }} />
                                                    <MyButton name={t('common.btnReject')} onClick={()=> {
                                                        setSelectedProject({aid: row._id, status: APPLICATION_STATUS.REJECTED});
                                                        setOpen(true);
                                                        }} />
                                                </Box>
                                            </StyledTableCell>:<StyledTableCell align="center"></StyledTableCell>
                                            }
                                        </StyledTableRow>
                                    );
                                })}                                
                            {isLoading?<MyBackdrop open={isLoading}><CircularProgress color="inherit" /></MyBackdrop> : data.length === 0?<StyledTableRow>
                                        <StyledTableCell colSpan={9} align="center">{t('common.noTableData')}</StyledTableCell></StyledTableRow>:null}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={9} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <CreateApplicationAlertDialogSlide isOpen={openAccept} title={t('application.updateApplicationTitle')} text={t('application.acceptApplicationWithProgress')} handleCancelClose={()=> {setOpenAccept(false)}} handleConfirmClose={() => {
                setOpenAccept(false);                 
                setSelectedProject({...selectedProject, progress: progress});
                setOpen(true);
            }} setProgress={setProgress} progress={progress}/>
            
            <AlertDialogSlide isOpen={open} title={t('application.updateApplicationTitle')} text={t(selectedProject.status===APPLICATION_STATUS.ACCEPTED?'application.acceptApplication':selectedProject.status===APPLICATION_STATUS.REJECTED?'application.rejectApplication':selectedProject.status===APPLICATION_STATUS.CANCELED?'application.withdrawApplication':"", {project: selectedProject.name, progress: progress})} 
            handleConfirmClose={()=> {
                setOpen(false);
                userService.updateApplication(selectedProject).then((result) => {
                if (result.status === 200) {
                    dispatch(getApplications());
                    setOpenResult(true);
                }
                else {
                    //TODO error page
                }
            })}}
            handleCancelClose={()=> {setOpen(false)}}/>
            <AlertDialogSlide isOpen={openResult} title={t('application.updateApplicationTitle')} text={t('application.updateApplicationDone')} handleOKClose={()=> {setOpenResult(false)}}/>
        </Box>
    );
}
