import { useTheme } from "@mui/material";
import { Box } from "@mui/system";
import Header from "../header/Header";
import Navbar from "../navbar/Navbar";

const LayoutNoTitle = (props) => {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex' }}>
            <Navbar />
            <Box sx={{display: 'flex', flexGrow: 1, flexDirection: "column"}}>
                <Header />
                <Box
                    component="main"
                    sx={{ marginTop: `${theme.header.height}px`, height: `calc(100vh - ${theme.header.height}px)` }}>
                    {props.children}
                </Box>
            </Box>
        </Box>
    );
}

export default LayoutNoTitle;