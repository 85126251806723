import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import UserService from "../../../services/user.service";

const INITIAL_STATE = {
    totalNum: 0,
    pageNumber: 0,
    list: [],
    noOfPending: 0,
    noOfWaitingReply: 0,
    isLoading: false,
    project: {}
}

export const getApplications = createAsyncThunk(
    "application/all",
    async (thunkAPI) => {
        try {
            let response = await UserService.getApplications()
                .catch(function (error) {
                    console.log(error);
                    return thunkAPI.rejectWithValue("SYSTEM_ERROR");
                });

            return response.data;

        } catch (e) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
);


export const applicationSlice = createSlice({
    name: 'application',
    initialState: INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(getApplications.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getApplications.fulfilled, (state, action) => {
                state.totalNum = action.payload.data.data.length;
                state.list = action.payload.data.data;
                state.noOfPending = action.payload.data.noOfPending;
                state.noOfWaitingReply = action.payload.data.noOfWaitingReply;
                state.isLoading = false;
            })
            .addCase(getApplications.rejected, (state, action) => {
                state = {
                    ...INITIAL_STATE,
                    errorMessage: "System Error"
                }
            })
    }
});

export default applicationSlice.reducer