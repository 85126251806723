import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { MyButton } from '../button/Button';
import { formatServerDateTime, MESSAGE_TYPE } from '../../util/util';
import { useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux';



export default function NotificationBox(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const timezone = useSelector((state) => state.user.timezone);

  return (
    <Card sx={{ m:3, backgroundColor: "#F4FAFF", }}>
      <CardContent sx={{ minWidth: "200px", display: "flex", flexDirection: "column", paddingBottom: "16px !important"}}>
        <Typography sx={{ fontSize: 16 }} color="#121F3E" gutterBottom>
        {t('message.title_' + props.data.type)}
        </Typography>        
        <Typography sx={{ fontSize: 10, mt: -1 }} color="#121F3E" gutterBottom>
        {formatServerDateTime(props.data.createdAt, timezone)}
        </Typography>
        <Typography sx={{fontSize: 12}} color="text.secondary">
            {t('message.content_' + props.data.type + (props.data.isSelf?"_me":"_from"), props.data)}
        </Typography>
        {
          props.showButton ?
          <MyButton name={t('common.btnView')} sx={{width: "150px", alignSelf: 'center', mt: "12px"}} 
          onClick={()=>{
            if (props.data.type === MESSAGE_TYPE.PROJECT_NEW) {
              navigate("/project");
            }
            else if (props.data.type === MESSAGE_TYPE.INVITATION_NEW || props.data.type === MESSAGE_TYPE.INVITATION_REJECT || props.data.type === MESSAGE_TYPE.INVITATION_ACCEPT || props.data.type === MESSAGE_TYPE.INVITATION_CANCEL ) {
              navigate("/invitation");
            }
            else if (props.data.type === MESSAGE_TYPE.APPLICATION_NEW || props.data.type === MESSAGE_TYPE.APPLICATION_REJECT || props.data.type === MESSAGE_TYPE.APPLICATION_ACCEPT || props.data.type === MESSAGE_TYPE.APPLICATION_CANCEL ) {
              navigate("/application");
            }
            else if (props.data.type === MESSAGE_TYPE.EMPLOYMENT_UPLOAD ) {
              navigate("/worker/employment");
            }
            else if (props.data.type === MESSAGE_TYPE.ATTENDENCE_UPLOAD ) {
              navigate("/worker/attendance");
            }
            else if (props.data.type === MESSAGE_TYPE.PAYROLL_TRIGGER ) {
              navigate("/worker/payroll");
            }
          }}/>
          :
          <></>
        }
      </CardContent>
    </Card>
  );
}