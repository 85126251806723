import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { MyOutlinedButton } from '../button/Button';
import { EnhancedTableHead, getComparator, stableSort, StyledTableCell, StyledTableRow } from './EnhancedTable';
import { useDispatch, useSelector } from 'react-redux';
import { formatServerDateTime } from '../../util/util';
import { useNavigate } from 'react-router-dom';
import DatePicker from '../datePicker/DatePicker';
import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { MyBackdrop } from '../backdrop/Backdrop';

const EnhancedTableToolbar = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();      
    const theme = useTheme();


    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}
        >
            <Stack direction={"row"} spacing={3} sx={{ my: 1 }} alignItems="center" >
                <DatePicker handleChange={props.setStartDate} inputFormat="yyyy-MM-dd" value={props.startDate} title={t("common.startDate")} />
                <Typography color={theme.palette.gray[800]}>
                    {t('common.to')}
                </Typography>
                <DatePicker handleChange={props.setEndDate} inputFormat="yyyy-MM-dd" value={props.endDate} title={t("common.endDate")} />
            </Stack>
            <MyOutlinedButton name={t('attendance.btnImport')} onClick={()=>{navigate("/worker/attendance/import")}} />
        </Toolbar>
    );
};


export default function EnhancedAttendanceTable(props) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const timezone = useSelector((state) => state.user.timezone);
    const data = useSelector((state) => state.worker.attendance.list);
    const isLoading = useSelector((state) => state.worker.attendance.isLoading);

    const headCells = [
        {
            id: 'name',
            align: 'left',
            disablePadding: false,
            label: t("worker.name"),
        },
        {
            id: 'hkid',
            align: 'left',
            disablePadding: false,
            label: t("worker.hkid"),
        },
        {
            id: 'employmentStatus',
            align: 'left',
            disablePadding: false,
            label: t("attendance.employmentStatus"),
        },
        {
            id: 'checkin',
            align: 'left',
            disablePadding: false,
            label: t("attendance.checkin"),
        },
        {
            id: 'checkout',
            align: 'left',
            disablePadding: false,
            label: t("attendance.checkout"),
        },
        {
            id: 'status',
            align: 'left',
            disablePadding: false,
            label: t("attendance.status"),
        },
        {
            id: 'createdAt',
            align: 'left',
            disablePadding: false,
            label: t("attendance.createdTime"),
        },
    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar dispatch={dispatch} startDate={props.startDate} endDate={props.endDate} setStartDate={props.handleStartDateChange} setEndDate={props.handleEndDateChange}/>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(data, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <StyledTableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <StyledTableCell align="left">{row.employment.worker.name}</StyledTableCell>
                                            <StyledTableCell align="left">{row.employment.worker.hkid}</StyledTableCell>
                                            <StyledTableCell align="left">{row.employment.status}</StyledTableCell>
                                            <StyledTableCell align="left">{formatServerDateTime(row.checkIn, timezone)}</StyledTableCell>
                                            <StyledTableCell align="left">{formatServerDateTime(row.checkout, timezone)}</StyledTableCell>
                                            <StyledTableCell align="left">{row.status}</StyledTableCell>
                                            <StyledTableCell align="left">{formatServerDateTime(row.createdAt, timezone)}</StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            {isLoading?<MyBackdrop open={isLoading}><CircularProgress color="inherit" /></MyBackdrop> : data.length === 0?<StyledTableRow>
                                        <StyledTableCell colSpan={7} align="center">{t('common.noTableData')}</StyledTableCell></StyledTableRow>:null}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={7} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[20, 50, 100, 200]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
