import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Tree, TreeNode } from 'react-organizational-chart';
import { Button, Tooltip, Box } from '@mui/material';
import { formatDate, numberToCurrency } from '../../util/util';
import { useTranslation } from 'react-i18next';

const StyledNode = styled(Tooltip)(({ theme }) => ({
    padding: '5px',
    borderRadius: "14px",
    paddingLeft: "14px",
    paddingRight: "14px",
    fontSize: "12px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
}));

const StyledButton = styled(Button)(({ theme }) => ({
    "&:hover": {
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.white,
    }
}));

const tooltipComp = (props, t) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box>{t('project.projectTitle') + ": " + props.name}</Box>
            <Box>{t('project.price') + ": " + numberToCurrency(props.price)}</Box>
            <Box>{t('project.startDate') + ": " + formatDate(props.startDate)}</Box>
            <Box>{t('project.endDate') + ": " + formatDate(props.endDate)}</Box>
        </Box>
    );
}

const CompanyTree = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Tree
            lineWidth={'2px'}
            lineColor={theme.palette.primary.main}
            lineBorderRadius={'10px'}
            label={<StyledNode title={tooltipComp(props.tree, t)}><StyledButton>{props.tree.company.companyName}</StyledButton></StyledNode>}>
            <CompanyTreeNode data={props.tree.underlying} level={0} t={t}/>
        </Tree>
    );
}

const CompanyTreeNode = (props) => {
    const { data, level, t } = props;
    const tree = data.map((node, key) => {
        if (node.underlying.length === 0) {
            return <TreeNode key={key} label={<StyledNode title={tooltipComp(node, t)}><StyledButton>{node.company.companyName}</StyledButton></StyledNode>} />
        }
        return (
        <TreeNode key={key} label={<StyledNode title={tooltipComp(node, t)}><StyledButton>{node.company.companyName}</StyledButton></StyledNode>}>
            <CompanyTreeNode key={key} name={node.company.companyName} data={node.underlying} level={level + 1} t={t}/>
        </TreeNode>);
    })

    return (
        <>
            {tree}
        </>
    );
}

export default CompanyTree;