import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { MyOutlinedButton } from '../button/Button';
import { EnhancedTableHead, getComparator, stableSort, StyledInnerTableCell, StyledTableCell, StyledTableContainer, StyledTableRow } from './EnhancedTable';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Collapse, IconButton, TableHead, Typography, useTheme } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { StatusCircle } from '../statusCircle/StatusCircle';
import { getPendingPayrollPerProject } from '../../redux/features/project/projectSlice';
import { MyBackdrop } from '../backdrop/Backdrop';

const EnhancedTableToolbar = (props) => {
    const { t } = useTranslation();

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
            }}
        >
                    <MyOutlinedButton name={t('common.btnRefresh')} onClick={() => { props.refreshData(); }} />
       
          
        </Toolbar>
    );
};

const CONST_INDEN = 10;
const WIDTH = 80;

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const data = useSelector((state) => state.project.cashflow.data);
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();

    let arrowCellWidth = (WIDTH - props.level * CONST_INDEN) +"px";

    return (
        <React.Fragment>
            <StyledTableRow key={row.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell sx={{ width: arrowCellWidth}}>
                    {row.underlying.length > 0 || row.totalPendingPayroll > 0?
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {if(!open) { dispatch(getPendingPayrollPerProject({ pid: row.id })); } setOpen(!open); }}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>:null}
                </StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}  align="left">{row.company}</StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}  align="center">
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center" }}>
                        <StatusCircle value={row.status.danger} color={theme.palette.red[400]} background={theme.palette.white}/>
                        <StatusCircle value={row.status.warning} color={theme.palette.orange[400]} background={theme.palette.white}/>
                        <StatusCircle value={row.status.normal} color={theme.palette.green[400]} background={theme.palette.white}/>
                    </Box>
                </StyledTableCell>
                <StyledTableCell sx={{ width: 150 }} align="left">{row.projectManager}</StyledTableCell>
                <StyledTableCell sx={{ width: 100 }} align="left">{row.totalPrice}</StyledTableCell>
                <StyledTableCell sx={{ width: 100 }} align="left">{row.totalPayroll}</StyledTableCell>
                <StyledTableCell sx={{ width: 100 }} align="left">{row.totalPendingPayroll}</StyledTableCell>
                <StyledTableCell sx={{ width: 100 }} align="left">{row.projectProgress + "%"}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell sx={{ padding: 0, paddingLeft: "10px" }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ marginTop: 1 }}>
                            {data[row.id]?
                            <React.Fragment>
                            <Typography variant="h7" gutterBottom component="div" sx={{color: theme.palette.primary.main, paddingLeft: 1}}>
                                {t('cashflow.pendingSalaryDetail')}
                            </Typography>
                            <Table size="small" aria-label="purchases" sx={{mb: 3, border: 1, color: theme.palette.primary.secondary}}>
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledInnerTableCell>{t("worker.name")}</StyledInnerTableCell>
                                        <StyledInnerTableCell>{t("worker.hkid")}</StyledInnerTableCell>
                                        <StyledInnerTableCell>{t("worker.bank")}</StyledInnerTableCell>
                                        <StyledInnerTableCell>{t("worker.bankAccount")}</StyledInnerTableCell>
                                        <StyledInnerTableCell>{t("payroll.totalSalary")}</StyledInnerTableCell>
                                        <StyledInnerTableCell>{t("payroll.totalWorkingDays")}</StyledInnerTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                {data[row.id]?.map((workerRow) => (
                                    <StyledTableRow key={workerRow.hkid}>
                                        <StyledInnerTableCell align="left">{workerRow.name}</StyledInnerTableCell>
                                        <StyledInnerTableCell align="left">{workerRow.hkid}</StyledInnerTableCell>
                                        <StyledInnerTableCell align="left">{workerRow.bank}</StyledInnerTableCell>
                                        <StyledInnerTableCell align="left">{workerRow.bankAccount}</StyledInnerTableCell>
                                        <StyledInnerTableCell align="left">{workerRow.totalSalary}</StyledInnerTableCell>
                                        <StyledInnerTableCell align="left">{workerRow.totalWorkingDays}</StyledInnerTableCell>
                                    </StyledTableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </React.Fragment>:null}
                            <StyledTableContainer>  
                                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
                                    <TableBody>
                                        {row.underlying.map((u) => {
                                            return <Row key={u.id} row={u} level={props.level+1}/>;
                                        })}           
                                    </TableBody>
                                </Table>
                            </StyledTableContainer>    
                        </Box>
                    </Collapse>
                </StyledTableCell>
            </StyledTableRow>
                                
        </React.Fragment>
    );
}


export default function EnhancedCashflowTable(props) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { t } = useTranslation();
    const data = useSelector((state) => state.project.cashflow.list);
    const isLoading = useSelector((state) => state.project.cashflow.isLoading);

    const headCells = [
        {
            id: 'icon',
            align: 'left',
            disablePadding: false,
            label: "",
        },
        {
            id: 'company',
            align: 'left',
            disablePadding: false,
            label: t("cashflow.company"),
        },
        {
            id: 'status',
            align: 'center',
            disablePadding: false,
            label: t("cashflow.status"),
        },
        {
            id: 'projectManager',
            align: 'left',
            disablePadding: false,
            label: t("cashflow.projectManager"),
        },
        {
            id: 'totalPrice',
            align: 'left',
            disablePadding: false,
            label: t("cashflow.totalPrice"),
        },
        {
            id: 'totalPayroll',
            align: 'left',
            disablePadding: false,
            label: t("cashflow.totalPayroll"),
        },
        {
            id: 'totalPendingPayroll',
            align: 'left',
            disablePadding: false,
            label: t("cashflow.totalPendingPayroll"),
        },
        {
            id: 'projectProgress',
            align: 'left',
            disablePadding: false,
            label: t("cashflow.projectProgress"),
        },
    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar refreshData={props.refreshData} />
                <StyledTableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(data, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <Row key={row.id} row={row} level={0}/>
                                    );
                                })}
                            {isLoading?<MyBackdrop open={isLoading}><CircularProgress color="inherit" /></MyBackdrop> : data.length === 0?<StyledTableRow>
                                        <StyledTableCell colSpan={8} align="center">{t('common.noTableData')}</StyledTableCell></StyledTableRow>:null}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </StyledTableContainer>
            </Paper>
        </Box>
    );
}
