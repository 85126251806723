import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box';
import LayoutNoTitle from '../../components/core/Layout';
import EnhancedAttendanceTable from '../../components/table/AttendanceTable';
import PageHeader from '../../components/header/PageHeader';
import { getAttendancePerProject } from '../../redux/features/worker/workerSlice';
import { formatDate } from '../../util/util';

const Attendance = () => {
    const dispatch = useDispatch();
    const selectedProject = useSelector((state) => state.project.selected);
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    const [startDate, setStartDate] = useState(firstDay);
    const [endDate, setEndDate] = useState(lastDay);

    useEffect(() => {
        console.log("Employment selectedProject: " + selectedProject);
        if (selectedProject!=="") {
            dispatch(getAttendancePerProject({ pid: selectedProject, startDate: formatDate(startDate), endDate: formatDate(endDate)}));
        }
    }, [selectedProject, startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <LayoutNoTitle>
            <Box sx={{pr: 4, pl: 4}}>
                <PageHeader isLess={true} handleChange={() => {}} />
                <EnhancedAttendanceTable startDate={startDate} endDate={endDate} handleStartDateChange={setStartDate} handleEndDateChange={setEndDate}/>
            </Box>
        </LayoutNoTitle>
    )
}

export default Attendance
