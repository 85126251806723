import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import UserService from "../../../services/user.service";

/*
const INITIAL_STATE = {
    _id: "",
    name: "",
    startDate: "",
    endDate: "",
    price: null,
    manager: "",
    createdDt: ""
}
*/

const INITIAL_STATE = {
    totalNum: 0,
    pageNumber: 0,
    list: [],
}

export const getCompanies = createAsyncThunk(
    "company/all",
    async (thunkAPI) => {
        try {
            let response = await UserService.getCompanies()
                .catch(function (error) {
                    return thunkAPI.rejectWithValue("SYSTEM_ERROR");
                });

            return response.data;

        } catch (e) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
);

export const companySlice = createSlice({
    name: 'company',
    initialState: INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(getCompanies.pending, (state, action) => {
            })
            .addCase(getCompanies.fulfilled, (state, action) => {
                state.totalNum = action.payload.data.length;
                state.list = [];
                action.payload.data.map((data) => {
                    return state.list.push({name: data.companyName, value: data._id});
                });
            })
            .addCase(getCompanies.rejected, (state, action) => {
                state = {
                    ...INITIAL_STATE,
                    errorMessage: "System Error"
                }
            })
    }
});

export default companySlice.reducer