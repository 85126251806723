var moment = require('moment-timezone');

export const API_URL_USER = process.env.REACT_APP_API_URL + "user/";
export const API_URL_WORKER = process.env.REACT_APP_API_URL + "worker/";
export const API_URL_DEVELOPER = process.env.REACT_APP_API_URL + "developer/";

export const ROLES = {
    'ADMIN': 'admin',
    'OPERATOR': 'operator',
}

export const COMPANY_TYPE = {
    'DEVELOPER': 'developer',
    'CONTRACTOR': 'contractor'
}

export const INVITATION_STATUS = {
    'CREATED': "created",
    'ACCEPTED': "accepted",
    'REJECTED': "rejected",
    'CANCELED': "canceled"
}

export const MESSAGE_TYPE = {
    'PROJECT_NEW': "project_new",
    'INVITATION_NEW': "invitation_created",
    'INVITATION_REJECT': "invitation_rejected",
    'INVITATION_ACCEPT': "invitation_accepted",
    'INVITATION_CANCEL': "invitation_canceled",
    'EMPLOYMENT_UPLOAD': "employment_upload",
    'ATTENDENCE_UPLOAD': "attendance_upload",
    'PAYROLL_TRIGGER': "payroll_trigger",
    'APPLICATION_NEW': "application_created",
    'APPLICATION_REJECT': "application_rejected",
    'APPLICATION_ACCEPT': "application_accepted",
    'APPLICATION_CANCEL': "application_canceled",
}  

export const INFO_TYPE = {
    'DANGER': "danger",
    'WARNING': "warning",
    "HEALTH": "health",
    "NORMAL": "normal"
}

export const APPLICATION_STATUS = {
    'CREATED': "created",
    'ACCEPTED': "accepted",
    'REJECTED': "rejected",
    'CANCELED': "canceled"
}

export const numberToCurrency = (number) => {
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export const formatDate = (d) => {
    return moment(d).format('YYYY-MMM-DD');
}

export const formatServerDateTime = (d, timezone) => {
    return moment(d).tz(timezone.name).format('YYYY-MMM-DD HH:mm:ss');
}

export const formatLocalDateTime = (d) => {
    return moment(d).format('YYYY-MMM-DD HH:mm:ss');
}

export const handleDownloadFile = (response) => {
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    const filename = response.headers
      .get("Content-Disposition")
      .split('=')[1];
    link.setAttribute('download', filename);

    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}