import { Box } from "@mui/material";

export const StatusCircle = (props) => {
    return (
        <Box sx={{ borderRadius: 15, background: props.color, width: 28, height: 28, display: "flex", justifyContent: "center", alignItem: "center", marginRight: "2px", marginLeft: "2px" }}>
            <div style={{ color: props.background, lineHeight: "28px" }}>
                {props.value}
            </div>
        </Box>
    );
}
