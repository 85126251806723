import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';
import { MyButton } from '../button/Button';
import { useTheme } from '@mui/material';

import { TextMiniInput } from '../input/TextInput';
import { Box } from '@mui/system';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateApplicationAlertDialogSlide(props) {
    const { t } = useTranslation();
    const theme = useTheme();
  
    React.useEffect(()=>{
      
    }, []);
  
    const handleClose = () => {
      if (props.handleOKClose !== undefined) props.handleOKClose();
      if (props.handleCancelClose !== undefined) props.handleCancelClose();
      if (props.handleConfirmClose !== undefined) props.handleConfirmClose();
    };
  
    return (
      <div>
        <Dialog
          open={props.isOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"        
        >
          {props.title?<DialogTitle sx={{display: 'flex', justifyContent: 'center', minWidth: "200px", paddingTop: "10px", paddingBottom: "10px", color: theme.palette.white, fontSize: "16px", backgroundColor: theme.palette.primary.main}}>{props.title}</DialogTitle>:null}
          {props.text?<DialogContent 
          sx={{display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center", minWidth: "200px", paddingTop: "14px !important", paddingBottom: "14px" }}>
            <DialogContentText id="alert-dialog-slide-description" sx={{color: theme.palette.primary.text, fontSize: "16px", mb: 1}}>
              {props.text}
            </DialogContentText>
            <Box sx={{display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: "center", color: theme.palette.primary.text}}>
                <TextMiniInput
                    required
                    id="progress"
                    label={t('application.progress')}
                    type="number"
                    variant="standard"
                    placeholder={t('application.progress')}
                    sx={{width: 120, mr: 1}}
                    value={props.progress?props.progress:""}
                    onChange={(event)=>props.setProgress(event.target.value)}
                />
                <Box sx={{display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: "center"}} >%</Box>
            </Box>
          </DialogContent>:null}
          <DialogActions sx={{display: 'flex', justifyContent: 'center', marginBottom: "6px", marginTop: "-4px"}}>
            {props.handleConfirmClose?<MyButton onClick={() => {if (props.progress !== "") {props.handleConfirmClose()}}} name={t('application.btnConfirmApplication')}/>:null}
            {props.handleCancelClose?<MyButton onClick={props.handleCancelClose} name={t('common.btnCancel')}/>:null}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  