import React, { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import PageHeader from '../../components/header/PageHeader';
import { useTranslation } from 'react-i18next';
import { MyBigButton } from '../../components/button/Button';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import LayoutWithBackButton from '../../components/core/LayoutWithBackButton';
import { MyBackdrop } from '../../components/backdrop/Backdrop';
import { getCompanies } from '../../redux/features/company/companySlice';
import { VerticalSelect } from '../../components/input/SelectorInput';
import { getWorkCategory } from '../../redux/features/setting/settingSlice';
import FormInput from '../../components/input/FormInput';
import userService from '../../services/user.service';
import AlertDialogSlide from '../../components/dialog/AlertDialogSlide';

const InvitationCreation = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();  
    const isLoading = useSelector((state) => state.invitation.isLoading);
    const workCategoryList = useSelector((state) => state.setting.workCategory);
    const [budget, setBudget] = useState("");
    const companyList = useSelector((state) => state.company.list);
    const [invitedCompany, setInvitedCompany] = useState("");
    const [category, setCategory] = useState("");
    const project = useSelector((state) => state.project.selected);
    const [open, setOpen] = useState(false);
    
    useEffect(()=> {
        dispatch(getCompanies());
        dispatch(getWorkCategory());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <LayoutWithBackButton title={t('page.invitationCreation')}>
            <Box sx={{ p: 4, pb: 1, mr: 2, mt: -10 }}>
            {isLoading ?
                <MyBackdrop
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </MyBackdrop> :
                <Fragment>
                    <PageHeader handleChange={() => {}} />
                    <VerticalSelect label={t("invitation.inviteCompany")} name={t("invitation.inviteCompany")} rows={companyList} value={invitedCompany} handleChange={(event) => setInvitedCompany(event.target.value)}/>
                    <VerticalSelect label={t("invitation.category")} name={t("invitation.category")} rows={workCategoryList} value={category} handleChange={(event) => setCategory(event.target.value)}/>
                    <FormInput title={t("invitation.budget")} fullWidth={true} inputAdornment={"$"} value={budget} handleChange={(event) => setBudget(event.target.value)} />
                    <Box sx={{display: "flex", justifyContent: "center", marginTop: "14px", marginBottom: "24px"}} >
                        <MyBigButton name={t('invitation.btnSendInvitation')} onClick={()=>{ 
                            userService.createInvitation({ project: project, invitedCompany: invitedCompany, category: category, budget: budget }).then((result) => {
                                if (result.status === 200) {
                                    setOpen(true);
                                }
                                else {
                                    //TODO error page
                                }
                            });
                        }}/>
                    </Box>
                </Fragment>
            }
            </Box>
            <AlertDialogSlide isOpen={open} title={t('page.invitationCreation')} text={t('invitation.dialogCreateInvitation')} handleOKClose={()=> {navigate("/invitation")}} />
        </LayoutWithBackButton>
        
    )
}

export default InvitationCreation