import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const BigButton = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 2,
  backgroundColor: theme.palette.main,
  borderColor: theme.palette.main,
  borderRadius: 20,
  minWidth: 200,
  marginLeft: 10,
  marginRight: 10,
  '&:hover': {
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.primary.main,
    borderWidth: 1.5,
    color: theme.palette.primary.main,
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.transparentBg,
    color: theme.palette.white
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
}));

export const MyBigButton = (props) => {
    return <BigButton {...props} variant="contained">{props.name}</BigButton>
}

export const MyBigUploadButton = (props) => {
  return <BigButton {...props} component="label" variant="contained">{props.name}{props.children}</BigButton>
}

const OutlinedButton = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.3,
  backgroundColor: theme.palette.white,
  borderColor: theme.palette.primary.main,
  borderRadius: 20,
  paddingLeft: 24,
  paddingRight: 24,
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.white
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.transparentBg,
    color: theme.palette.white
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
  '&:disabled': {
    color: '#8a8a8a',
    background: '#d8d8d8',
    border: 0
  },
  marginLeft: 10,
  marginRight: 10
}));

export const MyOutlinedButton = (props) => {
    return <OutlinedButton {...props} variant="contained" disableRipple>{props.name}</OutlinedButton>
}

const NormalButton = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 12,
  padding: '8px 12px',
  border: '1px solid',
  lineHeight: 1,
  backgroundColor: theme.palette.main,
  borderColor: theme.palette.main,
  borderRadius: 20,
  paddingLeft: 24,
  paddingRight: 24,
  '&:hover': {
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.primary.main,
    borderWidth: 1.5,
    color: theme.palette.primary.main,
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.transparentBg,
    color: theme.palette.white
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
}));

export const MyButton = (props) => {
    return <NormalButton {...props} variant="contained" disableRipple>{props.name}</NormalButton>
}