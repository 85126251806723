import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { MyOutlinedButton } from '../button/Button';
import { EnhancedTableHead, getComparator, stableSort, StyledTableCell, StyledTableRow } from './EnhancedTable';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, formatServerDateTime } from '../../util/util';
import { useNavigate } from 'react-router-dom';
import { MyBackdrop } from '../backdrop/Backdrop';
import { CircularProgress } from '@mui/material';

const EnhancedTableToolbar = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();  

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end"
            }}
        >
            <MyOutlinedButton name={t('employment.btnImport')} onClick={()=>{navigate("/worker/employment/import")}} />
        </Toolbar>
    );
};


export default function EnhancedEmploymentTable() {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const timezone = useSelector((state) => state.user.timezone);
    const data = useSelector((state) => state.worker.employment.list);
    const isLoading = useSelector((state) => state.worker.employment.isLoading);

    const headCells = [
        {
            id: 'project',
            align: 'left',
            disablePadding: false,
            label: t("project.project"),
        },
        {
            id: 'name',
            align: 'left',
            disablePadding: false,
            label: t("worker.name"),
        },
        {
            id: 'startDate',
            align: 'left',
            disablePadding: false,
            label: t("employment.startDate"),
        },
        {
            id: 'endDate',
            align: 'left',
            disablePadding: false,
            label: t("employment.endDate"),
        },
        {
            id: 'salary',
            align: 'left',
            disablePadding: false,
            label: t("employment.salary"),
        },
        {
            id: 'status',
            align: 'left',
            disablePadding: false,
            label: t("employment.status"),
        },
        {
            id: 'createdAt',
            align: 'left',
            disablePadding: false,
            label: t("employment.createdAt"),
        }
    ];


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar dispatch={dispatch}/>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(data, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <StyledTableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <StyledTableCell align="left">{row.projectName}</StyledTableCell>
                                            <StyledTableCell align="left">{row.employment.worker.name}</StyledTableCell>
                                            <StyledTableCell align="left">{formatDate(row.employment.startDate)}</StyledTableCell>
                                            <StyledTableCell align="left">{formatDate(row.employment.endDate)}</StyledTableCell>
                                            <StyledTableCell align="left">{row.employment.salary}</StyledTableCell>
                                            <StyledTableCell align="left">{row.employment.status}</StyledTableCell>
                                            <StyledTableCell align="left">{formatServerDateTime(row.employment.worker.createdAt, timezone)}</StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            {isLoading?<MyBackdrop open={isLoading}><CircularProgress color="inherit" /></MyBackdrop> : data.length === 0?<StyledTableRow>
                                        <StyledTableCell colSpan={9} align="center">{t('common.noTableData')}</StyledTableCell></StyledTableRow>:null}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
