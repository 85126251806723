import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom"
const PrivateRoute = ({ allowedRoles }) => {
    const location = useLocation();
    let auth = useSelector(state => state.user);
    let result = auth?.roles?.find(role => allowedRoles?.includes(role));
    if (result === undefined) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return (
        result? <Outlet /> : !auth.user? <Navigate to="/unauthorized" state={{ from: location }} replace />:  <Navigate to="/login" state={{ from: location }} replace />
    )
}

export default PrivateRoute;