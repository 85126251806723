import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';
import { MyButton } from '../button/Button';
import { useTheme } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const { t } = useTranslation();
  const theme = useTheme();
 
  const handleClose = () => {
    if (props.handleOKClose !== undefined) props.handleOKClose();
    if (props.handleCancelClose !== undefined) props.handleCancelClose();
    if (props.handleConfirmClose !== undefined) props.handleConfirmClose();
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"        
      >
        {props.title?<DialogTitle sx={{display: 'flex', justifyContent: 'center', minWidth: "200px", paddingTop: "10px", paddingBottom: "10px", color: theme.palette.white, fontSize: "16px", backgroundColor: theme.palette.primary.main}}>{props.title}</DialogTitle>:null}
        {props.text?<DialogContent 
        sx={{display: 'flex', justifyContent: 'center', alignItems: "center", minWidth: "200px", paddingTop: "14px !important", paddingBottom: "14px" }}>
          <DialogContentText id="alert-dialog-slide-description" sx={{color: theme.palette.primary.text, fontSize: "16px"}}>
            {props.text}
          </DialogContentText>
        </DialogContent>:null}
        <DialogActions sx={{display: 'flex', justifyContent: 'center', marginBottom: "6px", marginTop: "-4px"}}>
          {props.handleOKClose?<MyButton onClick={props.handleOKClose} name={t('common.btnOK')}/>:null}
          {props.handleConfirmClose?<MyButton onClick={props.handleConfirmClose} name={t('common.btnConfirm')}/>:null}
          {props.handleCancelClose?<MyButton onClick={props.handleCancelClose} name={t('common.btnCancel')}/>:null}
        </DialogActions>
      </Dialog>
    </div>
  );
}