import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import LayoutNoTitle from '../../components/core/Layout';
import InfoCard from '../../components/informationcard/InfoCard';
import { MailOutline } from '@mui/icons-material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import EnhancedApplicationTable from '../../components/table/ApplicationTable';
import { getApplications } from '../../redux/features/application/applicationSlice';

const Application = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const noOfPending = useSelector((state) => state.invitation.noOfPending);
    const noOfWaitingReply = useSelector((state) => state.invitation.noOfWaitingReply);

    useEffect(() => {
        dispatch(getApplications());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <LayoutNoTitle>
            <Box sx={{padding: "32px"}}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px'}}>
                    <InfoCard value={noOfPending} desc={t('application.btnPendingApplication')} Icon={ModeEditOutlineOutlinedIcon} />
                    <InfoCard value={noOfWaitingReply} desc={t('application.btnWaitingApplication')} Icon={MailOutline} />
                </Box>
                <EnhancedApplicationTable/>
            </Box>
        </LayoutNoTitle>
    )
}

export default Application
