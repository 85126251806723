import React from 'react'
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import "./backButton.scss";
import { useNavigate } from "react-router-dom"
import { Box, useTheme } from '@mui/material';


function BackButton(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
      <Box sx={{ display: "flex", flexDirection: "row", mb: 3, alignItems: "center", position: "sticky", width: "100%", top: theme.header.height, backgroundColor: "#fff", paddingTop: 4, paddingBottom: 3, zIndex: 50}} onClick={() => navigate(-1)}>
        <div className="backBtnCircle">
          <KeyboardArrowLeftRoundedIcon style={{ color: theme.palette.gray[600] }}/>
        </div>
        <p className="createProjectTitle" style={{ color: theme.palette.primary.main }}>{props.name}</p>
      </Box>
  )
}

export default BackButton