import React, { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import PageHeader from '../../components/header/PageHeader';
import { useTranslation } from 'react-i18next';
import { MyBigButton } from '../../components/button/Button';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import LayoutWithBackButton from '../../components/core/LayoutWithBackButton';
import { MyBackdrop } from '../../components/backdrop/Backdrop';
import { VerticalSelect } from '../../components/input/SelectorInput';
import { getApplicationType } from '../../redux/features/setting/settingSlice';
import FormInput from '../../components/input/FormInput';
import userService from '../../services/user.service';
import AlertDialogSlide from '../../components/dialog/AlertDialogSlide';
import { FileUploadField } from '../../components/fileUpload/FileUploadField';

const ApplicationCreation = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();  
    const isLoading = useSelector((state) => state.invitation.isLoading);
    const applicationTypeList = useSelector((state) => state.setting.applicationType);
    const [type, setType] = useState("");
    const [title, setTitle] = useState("");
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");
    const project = useSelector((state) => state.project.selected);
    const [open, setOpen] = useState(false);
    
    useEffect(()=> {
        dispatch(getApplicationType());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fileUploadProp = {
        accept: '*',
        onChange: (event) => {
            if (
                event.target.files !== null &&
                event.target?.files?.length > 0
            ) {
                console.log(`Saving ${event.target.value}`)                
                if (event.target.files[0]) {
                    setFile(event.target.files[0]);
                    setFileName(event.target.files[0].name);
                }
            }
        },
        onDrop: (event) => {
            console.log(`Drop ${event.dataTransfer.files[0].name}`)
            if (event.dataTransfer.files[0]) {
                setFile(event.dataTransfer.files[0])
                setFileName(event.dataTransfer.files[0].name)
            }
        },
    }

    return (
        <LayoutWithBackButton title={t('page.applicationCreation')}>
            <Box sx={{ p: 4, pb: 1, mr: 2, mt: -10 }}>
            {isLoading ?
                <MyBackdrop
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </MyBackdrop> :
                <Fragment>
                    <PageHeader handleChange={() => {}} isOnlyWithParent={true}/>
                    <FormInput title={t("application.title")} fullWidth={true} value={title} handleChange={(event) => setTitle(event.target.value)} />
                    <VerticalSelect label={t("application.type")} name={t("application.type")} rows={applicationTypeList} value={type} handleChange={(event) => setType(event.target.value)}/>                    
                    <FormInput title={t("application.uploadFile")} fullWidth={true} value={fileName} readOnly={true} />
                    <FileUploadField {...fileUploadProp} />
                    <Box sx={{display: "flex", justifyContent: "center", marginTop: "14px", marginBottom: "24px"}} >
                        <MyBigButton name={t('application.btnSendApplication')} onClick={()=>{ 
                            if (project !== "" && title !== "" && type !== "" && file !== "") {
                                userService.createApplication({ project: project, title: title, type: type, file: file }).then((result) => {
                                    if (result.status === 200) {
                                        setOpen(true);
                                    }
                                    else {
                                        //TODO error page
                                    }
                                });
                            }
                        }}/>
                    </Box>
                </Fragment>
            }
            </Box>
            <AlertDialogSlide isOpen={open} title={t('page.applicationCreation')} text={t('application.dialogCreateApplication')} handleOKClose={()=> {navigate("/application")}} />
        </LayoutWithBackButton>
        
    )
}

export default ApplicationCreation