import axios from 'axios';
import { API_URL_DEVELOPER, API_URL_USER } from '../util/util';
import authHeader from './authHeader';

class UserService {
  getProjects() {
    console.log("UserService getProjects");
    return axios.get(API_URL_USER + 'projects', { headers: authHeader() });
  }

  getProjectTree(pid) {
    console.log("UserService getProjectTree " + API_URL_USER + 'project/tree/'+pid);
    return axios.get(API_URL_USER + 'project/tree/'+pid, { headers: authHeader() });
  }

  getCompanies() {
    console.log("UserService getCompanies");
    return axios.get(API_URL_USER + 'company/all', { headers: authHeader() });
  }

  createProject(data) {
    console.log("UserService createProject");
    return axios.post(API_URL_DEVELOPER + 'project/create', data, { headers: authHeader() });
  }

  getInvitations() {
    console.log("UserService getInvitations");
    return axios.get(API_URL_USER + 'invitation/all', { headers: authHeader() });
  }

  getInvitationPerProject(pid) {
    console.log("UserService getInvitationPerProject pid " + pid);
    return axios.get(API_URL_USER + 'invitation/project/' + pid, { headers: authHeader() });
  }

  getWorkCategory() {
    console.log("UserService getWorkCategory");
    return axios.get(API_URL_USER + 'workcategory/all', { headers: authHeader() });
  }

  createInvitation(data) {
    console.log("UserService createInvitation");
    return axios.post(API_URL_USER+ 'invitation/create', data, { headers: authHeader() });
  }

  updateInvitation(data) {
    console.log("UserService updateInvitation ");
    console.log(data);
    return axios.post(API_URL_USER+ 'invitation/update', data, { headers: authHeader() });
  }

  getMessages() {
    console.log("UserService getMessages");
    return axios.get(API_URL_USER + 'message/all', { headers: authHeader() });
  }

  getWorkers() {
    console.log("UserService getWorkers");
    return axios.get(API_URL_USER + 'all', { headers: authHeader() });
  }

  getEmployments() {
    console.log("UserService getEmployments");
    return axios.get(API_URL_USER + 'employment/all', { headers: authHeader() });
  }

  getEmploymentPerProject(pid) {
    console.log("UserService getEmploymentPerProject pid " + pid);
    return axios.get(API_URL_USER + 'employment/project/' + pid, { headers: authHeader() });
  }

  createEmployment(data) {
    console.log("UserService createEmployment");
    console.log(data);
    return axios.post(API_URL_USER + 'employment/create', data, { headers: authHeader() });
  }

  getAttendancePerProject(pid, startDate, endDate) {
    console.log("UserService getAttendancePerProject pid " + pid + " | startDate: " + startDate + " | endDate: " + endDate);
    return axios.get(API_URL_USER + 'attendance/project/' + pid + "/?startDate="+startDate+"&endDate="+endDate, { headers: authHeader() });
  }

  createAttendance(data) {
    console.log("UserService createAttendance");
    console.log(data);
    return axios.post(API_URL_USER + 'attendance/create', data, { headers: authHeader() });
  }

  getPayrollPerProject(pid, startDate, endDate) {
    console.log("UserService getPayrollPerProject pid " + pid + " | startDate: " + startDate + " | endDate: " + endDate);
    return axios.get(API_URL_USER + 'payroll/project/' + pid + "/?startDate="+startDate+"&endDate="+endDate, { headers: authHeader() });
  }

  getPayrollHistoryByID(payloadID) {
    console.log("UserService getPayrollHistoryByID payloadID " + payloadID);
    return axios.get(API_URL_USER + 'payrollhistory/' + payloadID, { headers: authHeader() });
  }

  createPayroll(data) {
    console.log("UserService createPayroll");
    console.log(data);
    return axios.post(API_URL_USER + 'payroll/create', data, { headers: authHeader() });
  }

  getPayrollHistory(pid) {
    console.log("UserService getPayrollHistory pid " + pid);
    return axios.get(API_URL_USER + 'payrollhistory/project/' + pid, { headers: authHeader() });
  }
  
  getCashflow(pid) {
    console.log("UserService getCashflow pid " + pid);
    return axios.get(API_URL_USER + 'cashflow/project/' + pid, { headers: authHeader() });
  }

  getPendingPayrollPerProject(pid) {
    console.log("UserService getPendingPayrollPerProject pid " + pid);
    return axios.get(API_URL_USER + 'project/pendingpayroll/project/' + pid , { headers: authHeader() });
  }

  getApplications() {
    console.log("UserService getApplications");
    return axios.get(API_URL_USER + 'application/all', { headers: authHeader() });
  }

  downloadApplicationFile(aid) {
    console.log("UserService downloadApplicationFile aid: " + aid);
    return axios({
        url: API_URL_USER + 'application/download/' + aid,
        method: 'GET',
        responseType: 'blob', // important
        headers: authHeader(),
    });
  }

  getApplicationType() {
    console.log("UserService getApplicationType");
    return axios.get(API_URL_USER + 'applicationtype/all', { headers: authHeader() });
  }

  createApplication(data) {
    console.log("UserService createApplication");
    console.log(data);
    var formData = new FormData();
    formData.append("uploaded_file", data.file);
    formData.append("project", data.project);
    formData.append("title", data.title);
    formData.append("type", data.type);
    return axios({
      method: "post",
      url: API_URL_USER + 'application/create',
      data: formData,
      headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
    });
  }

  updateApplication(data) {
    console.log("UserService updateApplication");
    console.log(data);
    return axios.post(API_URL_USER+ 'application/update', data, { headers: authHeader() });
  }

  downloadAttendanceSample() {
    console.log("UserService downloadAttendanceSample");
    return axios({
        url: API_URL_USER + 'sample/download/attendance',
        method: 'GET',
        responseType: 'blob', // important
        headers: authHeader(),
    });
  }

  downloadEmploymentSample() {
    console.log("UserService downloadEmploymentSample");
    return axios({
        url: API_URL_USER + 'sample/download/employment',
        method: 'GET',
        responseType: 'blob', // important
        headers: authHeader(),
    });
  }

  downloadApplicationReportSample() {
    console.log("UserService downloadApplicationReportSample");
    return axios({
        url: API_URL_USER + 'sample/download/application',
        method: 'GET',
        responseType: 'blob', // important
        headers: authHeader(),
    });
  }
}

export default new UserService();