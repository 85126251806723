import { Box, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { HorizontalSelect } from '../input/SelectorInput';
import { styled } from '@mui/material/styles';
import { MyOutlinedButton } from '../button/Button';
import { formatDate, numberToCurrency } from '../../util/util';
import { getProjects, setSelectedProject } from '../../redux/features/project/projectSlice';

const HeaderTitle = styled(Box)(() => ({
    padding: "2px",
    fontWeight: "600",
    lineHeight: "24px"
}));

const HeaderContent = styled(Box)(() => ({
    padding: "2px",
    lineHeight: "24px"
}));

const PageHeader = (props) => {
    const projectList = useSelector((state) => state.project.list);
    const selectedProjectId = useSelector((state) => state.project.selected);
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let finalList = [];
    if (props.isOnlyWithParent) {
        finalList = projectList.filter((e) => e.parent !== undefined);
    }
    else if (props.isOnlyWithEmployment) {
        finalList = projectList.filter((e) => e.employment.length > 0);
    }
    else {
        finalList = projectList;
    }
    const filter = finalList.filter((p) => p._id === selectedProjectId);

    useEffect(() => {
        dispatch(getProjects());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    let selectedProjectInfo = {};
    if (filter.length > 0) {
        selectedProjectInfo = filter[0];
    }

    return (
        <div style={{marginBottom: 20, marginTop: 32}}>
            {props.btnName!==undefined?
            <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                <Box sx={{ fontSize: 26, color: theme.palette.primary.main, fontWeight: '600', marginLeft: "4px", marginBottom: "10px" }}></Box>
                <MyOutlinedButton sx={{zIndex: 100}} name={props.btnName} onClick={props.btnOnClick}/>
            </Box>:null}
            <Box sx={{ boxShadow: 3, padding: "8px", borderRadius: 2, display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", color: theme.palette.gray[800] }}>
                <HorizontalSelect rows={finalList} label={t('projectTableItem.project')} value={filter.length > 0?selectedProjectInfo._id:""} handleChange={(id) => {dispatch(setSelectedProject(id)); props.handleChange(id);}}/>
                {(!props.isLess)&&filter.length > 0?
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "center", fontSize: "16px", padding: "8px" }}>
                    <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", marginRight: "10px"}}>
                        <HeaderTitle>{t('projectTableItem.project')}</HeaderTitle>   
                        <HeaderTitle>{t('projectTableItem.company')}</HeaderTitle>                        
                        <HeaderTitle>{t('projectTableItem.date')}</HeaderTitle>
                        <HeaderTitle>{t('projectTableItem.manager')}</HeaderTitle>
                        <HeaderTitle>{t('projectTableItem.price')}</HeaderTitle>
                    </Box>
                    <Box>
                        <HeaderContent>{selectedProjectInfo.name}</HeaderContent>
                        <HeaderContent>{selectedProjectInfo.company?.companyName}</HeaderContent>
                        <HeaderContent>{formatDate(selectedProjectInfo.startDate)} {t("common.to")} {formatDate(selectedProjectInfo.endDate)}</HeaderContent>
                        <HeaderContent>{selectedProjectInfo.manager}</HeaderContent>
                        <HeaderContent>{numberToCurrency(selectedProjectInfo.price)}</HeaderContent>
                    </Box>
                </Box>:null}
            </Box>
        </div>
    )
}

export default PageHeader