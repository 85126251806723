import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box';
import LayoutNoTitle from '../../components/core/Layout';
import { getMessages } from '../../redux/features/message/messageSlice';
import EnhancedMessageTable from '../../components/table/MessageTable';

const Message = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMessages());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <LayoutNoTitle>
            <Box sx={{padding: "32px"}}>
                <EnhancedMessageTable />
            </Box>
        </LayoutNoTitle>
    )
}

export default Message
