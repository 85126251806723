import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import i18n from '../../../language/i18n';
import userService from '../../../services/user.service';

const INITIAL_STATE = {
 workCategory: [],
 applicationType: [],
}

export const getWorkCategory = createAsyncThunk(
  "users/workcategory/all",
  async (thunkAPI) => {
      try {
          let response = await userService.getWorkCategory()
          .catch(function (error) {
            console.log(error);
              return thunkAPI.rejectWithValue("SYSTEM_ERROR");
          });

          return response.data;

      } catch (e) {
          console.log("Error", e.response.data)
          thunkAPI.rejectWithValue(e.response.data)
      }
  }
);

export const getApplicationType = createAsyncThunk(
  "users/applicationtype/all",
  async (thunkAPI) => {
      try {
          let response = await userService.getApplicationType()
          .catch(function (error) {
            console.log(error);
              return thunkAPI.rejectWithValue("SYSTEM_ERROR");
          });

          return response.data;

      } catch (e) {
          console.log("Error", e.response.data)
          thunkAPI.rejectWithValue(e.response.data)
      }
  }
);

export const settingSlice = createSlice({
  name: 'setting',
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder
        .addCase(getWorkCategory.fulfilled, (state, action) => {
            state.workCategory = [];
            action.payload.data.map((d) => {
              return state.workCategory.push({value: d._id, name: i18n.t('project.category.'+d.key)})
            });
        })
        .addCase(getWorkCategory.rejected, (state, action) => {
            // TODO
        })
        .addCase(getApplicationType.fulfilled, (state, action) => {
          state.applicationType = [];
          action.payload.data.map((d) => {
            return state.applicationType.push({value: d._id, name: i18n.t('application.applicationType.'+d.key)})
          });
        })
        .addCase(getApplicationType.rejected, (state, action) => {
            // TODO
        })
      }
})

export const { setPage } = settingSlice.actions

export default settingSlice.reducer