import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { MyOutlinedButton } from '../button/Button';
import { EnhancedTableHead, getComparator, stableSort, StyledTableCell, StyledTableRow } from './EnhancedTable';
import { useDispatch, useSelector } from 'react-redux';
import { getMessages } from '../../redux/features/message/messageSlice';
import { formatServerDateTime } from '../../util/util';
import { MyBackdrop } from '../backdrop/Backdrop';
import { CircularProgress } from '@mui/material';

const EnhancedTableToolbar = (props) => {
    const { t } = useTranslation();

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end"
            }}
        >
            <MyOutlinedButton sx={{marginRight: "10px"}} name={t('common.btnRefresh')} onClick={()=>{props.dispatch(getMessages())}} /> 
        </Toolbar>
    );
};


export default function EnhancedMessageTable() {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const timezone = useSelector((state) => state.user.timezone);
    const data = useSelector((state) => state.message.list);
    const isLoading = useSelector((state) => state.message.isLoading);

    const headCells = [
        {
            id: 'createdAt',
            align: 'left',
            disablePadding: false,
            label: t("message.createdTime"),
        },
        {
            id: 'title',
            align: 'left',
            disablePadding: false,
            label: t("message.title"),
        },
        {
            id: 'content',
            align: 'left',
            disablePadding: false,
            label: t("message.content"),
        }
    ];


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar dispatch={dispatch}/>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(data, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {

                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <StyledTableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <StyledTableCell component="th" align="left" id={labelId} scope="row" padding="normal">{formatServerDateTime(row.createdAt, timezone)}</StyledTableCell>
                                            <StyledTableCell align="left"> {t('message.title_' + row.type) }</StyledTableCell>
                                            <StyledTableCell align="left"> {t('message.content_' + row.type + (row.isSelf?"_me":"_from"), row)}</StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}                                
                            {isLoading?<MyBackdrop open={isLoading}><CircularProgress color="inherit" /></MyBackdrop> : data.length === 0?<StyledTableRow>
                                        <StyledTableCell colSpan={9} align="center">{t('common.noTableData')}</StyledTableCell></StyledTableRow>:null}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
