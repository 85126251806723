import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import UserService from "../../../services/user.service";

const INITIAL_STATE = {
    totalNum: 0,
    pageNumber: 0,
    list: [],
    noOfPending: 0,
    noOfWaitingReply: 0,
    isLoading: false,
    project: {}
}

export const getInvitations = createAsyncThunk(
    "invitation/all",
    async (thunkAPI) => {
        try {
            let response = await UserService.getInvitations()
                .catch(function (error) {
                    console.log(error);
                    return thunkAPI.rejectWithValue("SYSTEM_ERROR");
                });

            return response.data;

        } catch (e) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
);

export const getInvitationPerProject = createAsyncThunk(
    "invitation/project/:pid",
    async ({pid}, thunkAPI) => {
        try {
            let response = await UserService.getInvitationPerProject()
                .catch(function (error) {
                    console.log(error);
                    return thunkAPI.rejectWithValue("SYSTEM_ERROR");
                });

            return response.data;

        } catch (e) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
);

export const invitationSlice = createSlice({
    name: 'invitation',
    initialState: INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(getInvitations.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getInvitations.fulfilled, (state, action) => {
                state.totalNum = action.payload.data.data.length;
                state.list = action.payload.data.data;
                state.noOfPending = action.payload.data.noOfPending;
                state.noOfWaitingReply = action.payload.data.noOfWaitingReply;
                state.isLoading = false;
            })
            .addCase(getInvitations.rejected, (state, action) => {
                state = {
                    ...INITIAL_STATE,
                    errorMessage: "System Error"
                }
            })
            .addCase(getInvitationPerProject.pending, (state, action) => {
                state.project = undefined;
                state.isLoading = true;
            })
            .addCase(getInvitationPerProject.fulfilled, (state, action) => {
                state.project = action.payload.data;
                state.selected = action.payload.data._id;
                state.isLoading = false;
            })
            .addCase(getInvitationPerProject.rejected, (state, action) => {
                state.project = action.payload.data;
                state.errorMessage = "System Error";
                state.isLoading = false;
            })
    }
});

export default invitationSlice.reducer