import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "Welcome to React": "Welcome to React and react-i18next",
            "page": {
                "project": "Project",
                "projectTree": "Project Architeture",
                "projectCreation": "Project Creation",
                "invitation": "Invitation",
                "invitationCreation": "Invitation Creation",
                "employmentCreation": "Employment Import",
                "attendanceCreation": "Attendance Import",
                "payrollCreation": "Payroll Creation",
                "payrollHistory": "Payroll History",
                "applicationCreation": "Application Creation",
            },
            "projectTableTitle": "All Projects",
            "projectNewButton": "Create New Project",
            "projectTableItem": {
                "company": "Company",
                "project": "Project Name",
                "status": "Status",
                "date": "Date Range",
                "manager": "Project Manager",
                "price": "Price HKD",
                "action": "Action"
            },
            "project": {
                "project": "Project",
                "projectTitle": "Project Title",
                "desc": "Project Description",
                "siteAddress": "Site Address",
                "startDate": "Start Date",
                "endDate": "End Date",
                "initialFund": "Initial Fund(HKD)",
                "price": "Price(HKD)",
                "projectManager": "Project Manager",
                "approver": "Approver",
                "quantitySurveyor": "Quanitity Surveyor",
                "mainContractor": "Main Contractor",
                "submitBtn": "Create",
                "totalProjects": "No. of Porject",
                "totalAmount": "Total Amount",
                "dialogCreateProject": "Project is created",
                "category": {
                    "main_contractor": "Main Contractor",
                    "developer": "Developer",
                    "paint": "Paint",
                    "floor_tiles": "Floor Tiles",
                    "muddy_water": "Muddy Water"
                }
            },
            "loginBtn": "Login",
            "login": {
                "email": "Email",
                "password": "Password"
            },
            "status": {
                "warning": "Warning",
                "normal": "Normal",
                "danger": "Danger"
            },
            "invitation": {
                "date": "Created Datetime",
                "company": "Invited Company",
                "project": "Project",
                "statusS": "Status",
                "id": "Project ID",
                "category": "Category",
                "budget": "Budget",
                "action": "Action",
                "newInvitationBtn": "New Invitation",
                "btnPendingInvitation": "Need Your Action",
                "btnWaitingInvitation": "Waiting For Reply",
                "btnNewInvitation": "New Invitation",
                "status": {
                    "waitforReply": "Waiting for Reply",
                    "pending": "Pending",
                    "accepted": "Accepted",
                    "rejected": "Rejected",
                    "canceled": "Canceled"
                },
                "inviteCompany": "Invite Company Name",
                "btnSendInvitation": "Send Invitation",
                "dialogCreateInvitation": "Invitation is sent",
                "updateInvitationTitle": "Invitation Update",
                "updateInvitationDone": "Invitation status is updated",
                "acceptInvitation": `Accept invitation for {{project}}`,
                "rejectInvitation": `Reject invitation for {{project}}`,
                "withdrawInvitation": `Withdraw invitation for {{project}}`,
            },
            "application": {
                "btnPendingApplication": "Need Your Approval",
                "btnWaitingApplication": "Waiting For Approval",
                "newApplicationBtn": "New Application",
                "date": "Created Datetime",
                "project": "Project",
                "companyRequest": "Submitted Company",
                "company": "Approval Company",
                "statusS": "Status",
                "file": "Application File",
                "action": "Action",
                "title": "Title",
                "progress": "Project Progress",
                "status": {
                    "waitforReply": "Waiting for Reply",
                    "pending": "Pending",
                    "accepted": "Accepted",
                    "rejected": "Rejected",
                    "canceled": "Canceled"
                },
                "type": "Report Type",
                "applicationType": {
                    "midterm_report": "Midterm Report",
                    "final_report": "Final Report"
                },
                "dialogCreateApplication": "Application is created",
                "fileIconHover": "Click or drag to upload file",
                "fileIconDrop": "Drop file here",
                "uploadFile": "Upload File",
                "btnSendApplication": "Send Application",
                "btnConfirmApplication": "Confirm Application",
                "updateApplicationTitle": "Application Update",
                "updateApplicationDone": "Application status is updated",
                "acceptApplication": `Accept application for {{project}} and project progress will be updated to {{progress}}%.`,
                "rejectApplication": `Reject application for {{project}}`,
                "withdrawApplication": `Withdraw application for {{project}}`,
                "acceptApplicationWithProgress": "Please provide the updated project progress."
            },
            "unauthorized": {
                "title": "You are not authorized to access this page.",
                "backBtn": "Back to Login"
            },
            "user": {
                "firstname": "First Name",
                "lastname": "Last Name",
                "email": "Email",
                "role": "Roles",
                "company": "Company Name",
                "companyType": "Company Type"
            },
            "message": {
                "createdTime": "Created Time",
                "title": "Title",
                "content": "Content",
                "title_project_new": "Project Created",
                "title_invitation_created": "Invitation Created",
                "title_invitation_accepted": "Invitation Accepted",
                "title_invitation_rejected": "Invitation Rejected",
                "title_invitation_canceled": "Invitation Canceled",
                "title_invitation_created_from": "Invitation Received",
                "content_invitation_created_me": `You created a new invitation of project {{projectName}} to {{companyTo}}.`,
                "content_invitation_accepted_me": `You accepted the invitation of project {{projectName}} from {{companyTo}}.`,
                "content_invitation_rejected_me": `You rejected the invitation of project {{projectName}} from {{companyTo}}.`,
                "content_invitation_canceled_me": `You withdrawed the invitation of project {{projectName}} for {{companyTo}}.`,
                "content_invitation_created_from": `You received a new invitation of project {{projectName}} from {{companyFrom}}.`,
                "content_invitation_accepted_from": `The invitation of project {{projectName}} is accepted by {{companyFrom}}.`,
                "content_invitation_rejected_from": `The invitation of project {{projectName}} is rejected by {{companyFrom}}.`,
                "content_invitation_canceled_from": `The invitation of project {{projectName}} is canceled by {{companyFrom}}.`,
                "title_application_created": "Application Created",
                "title_application_accepted": "Application Accepted",
                "title_application_rejected": "Application Rejected",
                "title_application_canceled": "Application Canceled",
                "title_application_created_from": "Application Received",
                "content_application_created_me": `You created a new application of project {{projectName}} to {{companyTo}}.`,
                "content_application_accepted_me": `You accepted the application of project {{projectName}} from {{companyTo}}.`,
                "content_application_rejected_me": `You rejected the application of project {{projectName}} from {{companyTo}}.`,
                "content_application_canceled_me": `You withdrawed the application of project {{projectName}} for {{companyTo}}.`,
                "content_application_created_from": `You received a new application of project {{projectName}} from {{companyFrom}}.`,
                "content_application_accepted_from": `The application of project {{projectName}} is accepted by {{companyFrom}}.`,
                "content_application_rejected_from": `The application of project {{projectName}} is rejected by {{companyFrom}}.`,
                "content_application_canceled_from": `The application of project {{projectName}} is canceled by {{companyFrom}}.`,
                "title_employment_upload": "Employment Upload",
                "title_attendance_upload": "Attendance Upload",
                "title_payroll_trigger": "Payroll Trigger",
                "content_project_new_me": `You created a new project {{projectName}}.`,
                "content_employment_upload_me": `You uploaded an employment list of project {{projectName}}.`,
                "content_attendance_upload_me": `You uploaded an attendance list of project {{projectName}}.`,
                "content_payroll_trigger_me": `You triggered a payroll for project {{projectName}}.`,
            },
            "overview": {
                "project_in_danger": "Danger Projects",
                "project_in_warning": "Warning Projects",
                "project_in_healthy": "Healthy Projects",
                "chart_title": "Danger Project Chart",
                "project_complete_title": "Project Completion",
                "payroll_complete_title": "Payroll Completion"
            },
            "worker": {
                "name": "Name",
                "hkid": "HKID",
                "cert": "Cert",
                "safetycard": "Safety Card",
                "birth": "Birth",
                "gender": "Gender",
                "bank": "Bank",
                "bankAccount": "Bank Account",
                "createdAt": "Created Time"
            },
            "employment": {
                "startDate": "Start Date",
                "endDate": "End Date",
                "status": "Status",
                "salary": "Salary",
                "createdAt": "Created Time",
                "btnNew": "New Employment",
                "btnImport": "Import New Employment",
                "btnImport2": "Import Data",
                "dialogCreate": "Employment is imported."
            },
            "attendance": {
                "checkin": "Check In Time",
                "checkout": "Check Out Time",
                "employmentStatus": "Employment Status",
                "status": "Status",
                "btnImport": "Import New Attendance",
                "btnImport2": "Import Data",
                "dialogCreate": "Attendance is imported.",
                "createdTime": "Created Time",
            },
            "payroll": {
                "totalSalary": "Total Salary",
                "totalWorkingDays": "Total Working Days",
                "totalAmount": "Total Amount",
                "totalWorker": "No. of Workers",
                "btnPay": "Trigger a Payroll",
                "btnPayHistory": "History",
                "dialogCreate": "Payroll is done.",
                "invalidPayrollList": "Payroll cannot be triggered as result is timeout. Please try again."
            },
            "cashflow": {
                "company": "Company",
                "status": "Status",
                "projectManager": "Project Manager",
                "totalPrice": "Total Fund",
                "totalPayroll": "Total Paid Payroll",
                "totalPendingPayroll": "Total Pending Payroll",
                "projectProgress": "Project Progress",
                "pendingSalaryDetail": "Pending Salary Details"
            },
            "common": {
                "to": "to",
                "btnOK": "OK",
                "btnConfirm": "Confirm",
                "btnView": "View",
                "btnViewProject": "View Project",
                "btnCreate": "Create",
                "btnAccept": "Accept",
                "btnReject": "Reject",  
                "btnCancel": "Cancel",  
                "btnWithdraw": "Withdraw",  
                "btnRefresh": "Refresh",  
                "btnDownload": "Download",  
                "alertSelectProject": "Please select a project",
                "btnSelectFile": "Select file", 
                "startDate": "Start Date",
                "endDate": "End Date",
                "createDate": "Creation Date",
                "createBy": "Created By",
                "status": "Status",
                "space": " ",
                "noTableData": "No Data Received"
            }
        },

    },
    fr: {
        translation: {
            "Welcome to React": "Bienvenue à React et react-i18next"
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;