import React, { Fragment, useEffect } from 'react';
import Box from '@mui/material/Box';
import CompanyTree from '../../components/tree/CompanyTree';
import PageHeader from '../../components/header/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProjectTree, setSelectedProject } from '../../redux/features/project/projectSlice';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import LayoutWithBackButton from '../../components/core/LayoutWithBackButton';
import { MyBackdrop } from '../../components/backdrop/Backdrop';

export const ProjectChart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const tree = useSelector((state) => state.project.tree.list);
    const isLoading = useSelector((state) => state.project.tree.isLoading);
    const { t } = useTranslation();

    useEffect(() => {
        if (state?.pid === undefined) {
            navigate("/", { replace: true });
        }
        else {
            dispatch(setSelectedProject(state.pid));
            dispatch(getProjectTree({ pid: state.pid }));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <LayoutWithBackButton title={t('page.projectTree')}>
            <Box sx={{ p: 4, pb: 1, mr: 2, mt: -10}}>
            {isLoading ?
                <MyBackdrop
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </MyBackdrop> :
                <Fragment>
                    <PageHeader handleChange={(id) => {dispatch(getProjectTree({ pid: id }));}} />
                    <CompanyTree tree={tree} />
                </Fragment>
            }
            </Box>
        </LayoutWithBackButton>
    )
}
