import { Home } from "./pages/home/Home";
import { Login } from "./pages/login/Login";
import { Project } from "./pages/project/Project";
import Invitation from "./pages/invitation/Invitation";
import { ProjectCreation } from "./pages/project/ProjectCreation";
import Application from "./pages/application/Application";
import InvitationCreation from "./pages/invitation/InvitationCreation";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { themeDeveloper, themeContractor } from "./util/theme";
import { store, persistor } from './redux/store';
import { Provider, useSelector } from 'react-redux';
import './language/i18n';
import { ProjectChart } from "./pages/project/ProjectChart";
import PrivateRoute from "./util/PrivateRoute";
import { Unauthorized } from "./pages/unauthorized/Unauthorized";
import { PersistGate } from "redux-persist/integration/react";
import { COMPANY_TYPE, ROLES } from "./util/util";
import { Setting } from "./pages/setting/Setting";
import Message from "./pages/message/Message";
import Employment from "./pages/worker/Employment";
import Attendance from "./pages/worker/Attendance";
import Payroll from "./pages/worker/Payroll";
import EmploymentCreation from "./pages/worker/EmploymentCreation";
import AttendanceCreation from "./pages/worker/AttendanceCreation";
import PayrollHistory from "./pages/worker/PayrollHistory";
import { Cashflow } from "./pages/cashflow/Cashflow";
import ApplicationCreation from "./pages/application/ApplicationCreation";
import { SampleFile } from "./pages/setting/SampleFile";


const InnerApp = () => {
  const companyType = useSelector(state => state.user.company.type);

  let theme = themeDeveloper;
  if (companyType === COMPANY_TYPE.DEVELOPER) {
    theme = themeDeveloper;
  }
  else if (companyType === COMPANY_TYPE.CONTRACTOR) {
    theme = themeContractor;
  }

  return (
    <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="login" element={<Login/>} />
              <Route path="unauthorized" element={<Unauthorized/>} />
              <Route element={<PrivateRoute allowedRoles={[ROLES.ADMIN, ROLES.OPERATOR]}/>}>
                <Route path="/" element={<Home/>} />
                <Route path="home" element={<Home/>} />
                <Route path="project" element={<Project/>} />
                <Route path="invitation" element={<Invitation/>} />
                <Route path="invitation/create" element={<InvitationCreation/>} />
                <Route path="project/create" element={<ProjectCreation/>} />
                <Route path="project/chart" element={<ProjectChart/>} />
                <Route path="application" element={<Application/>} />
                <Route path="application/create" element={<ApplicationCreation/>} />
                <Route path="setting/profile" element={<Setting/>} />
                <Route path="setting/sample" element={<SampleFile/>} />
                <Route path="messages" element={<Message/>} />
                <Route path="worker/employment" element={<Employment/>} />
                <Route path="worker/employment/import" element={<EmploymentCreation/>} />
                <Route path="worker/payroll" element={<Payroll/>} />
                <Route path="worker/payroll/history" element={<PayrollHistory/>} />
                <Route path="worker/attendance" element={<Attendance/>} />
                <Route path="worker/attendance/import" element={<AttendanceCreation/>} />
                <Route path="cashflow" element={<Cashflow/>} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
  );
}


function App() {
  console.log("Env: " + process.env.NODE_ENV + " | API_URL: " + process.env.REACT_APP_API_URL);
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <InnerApp/>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
