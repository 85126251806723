import React, { useState } from 'react';
import Box from '@mui/material/Box';
import PageHeader from '../../components/header/PageHeader';
import { useTranslation } from 'react-i18next';
import { MyBigButton, MyBigUploadButton } from '../../components/button/Button';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import LayoutWithBackButton from '../../components/core/LayoutWithBackButton';
import userService from '../../services/user.service';
import AlertDialogSlide from '../../components/dialog/AlertDialogSlide';
import { UploadFile } from '@mui/icons-material';
import { read, utils } from 'xlsx';
import EmploymentPreviewTable from '../../components/table/EmploymentPreviewTable';

const EmploymentCreation = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const project = useSelector((state) => state.project.selected);
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [file, setFile] = useState("");

    const handleImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    setFile(rows)
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }
    
    return (
        <LayoutWithBackButton title={t('page.employmentCreation')}>
            <Box sx={{ p: 4, pb: 1, mr: 2, mt: -10 }}>
                <PageHeader handleChange={() => {}} />
                {file===""?null:<EmploymentPreviewTable data={file}/>}
                <Box sx={{display: "flex", justifyContent: "center", marginTop: "14px", marginBottom: "24px"}} >
                    <MyBigUploadButton startIcon={<UploadFile />} name={t('common.btnSelectFile')}>
                        <input type="file" accept=".xlsx" hidden onChange={(e)=>{handleImport(e);}} onClick={(event)=> { event.target.value = null }} />
                    </MyBigUploadButton>
                    {file !== ""?
                    <MyBigButton name={t('employment.btnImport2')} onClick={()=>{ 
                        if (project === "" || project === undefined) {
                            setAlertOpen(true);
                        }
                        else {
                            userService.createEmployment({project: project, employment: file}).then((result) => {
                                if (result.status === 200) {
                                    setOpen(true);
                                }
                                else {
                                    //TODO error page
                                }
                            });
                        }
                    }}/>:null}
                </Box>
            </Box>
            <AlertDialogSlide isOpen={open} title={t('page.employmentCreation')} text={t('employment.dialogCreate')} handleOKClose={()=> {navigate("/worker/employment")}} />
            <AlertDialogSlide isOpen={alertOpen} title={t('page.employmentCreation')} text={t('common.alertSelectProject')} handleOKClose={()=> {setAlertOpen(false);}} />
        </LayoutWithBackButton>
        
    )
}

export default EmploymentCreation