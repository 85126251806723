import { createTheme } from '@mui/material/styles';
import palette from './palette.scss'

const COMMON_COMPONENTS = {
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '& fieldset': {
                        borderRadius: '24px',
                      },
                },
                input: {
                    color: palette.grayDark,
                }
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                }
            }
        }
    },
    header: {
        height: 100
    }
}

export const themeDeveloper = createTheme({
    palette: {
        primary: {
            main: palette.primary,
            secondary: palette.blueBackground,
            background: palette.blueBackground,
            transparentBg: palette.blueTransparentBg,
            text: palette.blueDark,
            subMenu: {
                bg: palette.blueSubmenuBg,
                selectedBg: palette.blueSubmenuSelectedBg,
            }
        },
        white: "#FFF",
        dark: "#000",
        red: {
            400: palette.red,
            600: palette.redDark
        },
        orange: {
            400: palette.orange,
            600: palette.orangeDark
        },
        green: {
            400: palette.green,
            600: palette.greenDark
        },
        blue: {
            400: palette.bluewhite,
            600: palette.blueDark
        },
        gray: {
            600: palette.gray,
            800: palette.grayDark
        }
    },
    ...COMMON_COMPONENTS
});

export const themeContractor = createTheme({
    palette: {
        primary: {
            main: palette.purple,
            secondary: palette.purpleSecondary,
            background: palette.purpleBackground,
            transparentBg: palette.purpleTransparentBg,
            text: palette.purpleText,
            subMenu: {
                bg: '#6b4d89',
                selectedBg: '#3d3346',
            }
        },
        white: "#FFF",
        dark: "#000",
        red: {
            400: palette.red,
            600: palette.redDark
        },
        orange: {
            400: palette.orange,
            600: palette.orangeDark
        },
        green: {
            400: palette.green,
            600: palette.greenDark
        },
        blue: {
            600: palette.blueDark
        },
        gray: {
            600: palette.gray,
            800: palette.grayDark
        }
    },
    ...COMMON_COMPONENTS
});

export const themeAdmin = createTheme({
    palette: {
        primary: {
            main: "#000"
        },
        white: "#FFF",
        dark: "#000",
        red: {
            400: palette.red,
            600: palette.redDark
        },
        orange: {
            400: palette.orange,
            600: palette.orangeDark
        },
        green: {
            400: palette.green,
            600: palette.greenDark
        },
        blue: {
            600: palette.blueDark
        },
        gray: {
            600: palette.gray,
            800: palette.grayDark
        }
    },
    ...COMMON_COMPONENTS
});