import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import UserService from "../../../services/user.service";

const INITIAL_STATE = {
    employment: {
        list: [],
        totalNum: 0,
        pageNumber: 0,
        isLoading: false,
    },
    attendance: {
        list: [],
        totalNum: 0,
        pageNumber: 0,
        isLoading: false,
    },
    payroll: {
        list: [],
        totalNum: 0,
        pageNumber: 0,
        history: {
            list: [],
            totalNum: 0,
            pageNumber: 0,
            data: {},
            isLoading: false,
        },
        totalAmount: 0,
        totalWorker: 0,
        totalWorkingDays: 0,
        payrollListID: "",
        isLoading: false,
    },
}

export const getWorkers = createAsyncThunk(
    "worker/all",
    async (thunkAPI) => {
        try {
            let response = await UserService.getWorkers()
                .catch(function (error) {
                    console.log(error);
                    return thunkAPI.rejectWithValue("SYSTEM_ERROR");
                });

            return response.data;

        } catch (e) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
);


export const getEmployments = createAsyncThunk(
    "worker/employment/all",
    async (thunkAPI) => {
        try {
            let response = await UserService.getEmployments()
                .catch(function (error) {
                    console.log(error);
                    return thunkAPI.rejectWithValue("SYSTEM_ERROR");
                });

            return response.data;

        } catch (e) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
);

export const getEmploymentPerProject = createAsyncThunk(
    "worker/employment/project/:pid",
    async ({pid}, thunkAPI) => {
        try {
            let response = await UserService.getEmploymentPerProject(pid)
                .catch(function (error) {
                    console.log(error);
                    return thunkAPI.rejectWithValue("SYSTEM_ERROR");
                });

            return response.data;

        } catch (e) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
);

export const getAttendancePerProject = createAsyncThunk(
    "worker/attendance/project/:pid",
    async ({pid, startDate, endDate}, thunkAPI) => {
        try {
            let response = await UserService.getAttendancePerProject(pid, startDate, endDate)
                .catch(function (error) {
                    console.log(error);
                    return thunkAPI.rejectWithValue("SYSTEM_ERROR");
                });

            return response.data;

        } catch (e) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
);

export const getPayrollPerProject = createAsyncThunk(
    "worker/payroll/project/:pid",
    async ({pid, startDate, endDate}, thunkAPI) => {
        try {
            let response = await UserService.getPayrollPerProject(pid, startDate, endDate)
                .catch(function (error) {
                    console.log(error);
                    return thunkAPI.rejectWithValue("SYSTEM_ERROR");
                });

            return response.data;

        } catch (e) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
);

export const getPayrollHistoryByID = createAsyncThunk(
    "worker/payrollhistory/:payloadID",
    async ({payloadID}, thunkAPI) => {
        try {
            let response = await UserService.getPayrollHistoryByID(payloadID)
                .catch(function (error) {
                    console.log(error);
                    return thunkAPI.rejectWithValue("SYSTEM_ERROR");
                });

            return response.data;

        } catch (e) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
);


export const getPayrollHistory = createAsyncThunk(
    "worker/payrollhistory/project/:pid",
    async ({pid}, thunkAPI) => {
        try {
            let response = await UserService.getPayrollHistory(pid)
                .catch(function (error) {
                    console.log(error);
                    return thunkAPI.rejectWithValue("SYSTEM_ERROR");
                });

            return response.data;

        } catch (e) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
);

export const workerSlice = createSlice({
    name: 'worker',
    initialState: INITIAL_STATE,
    extraReducers: (builder) => {
        builder
            .addCase(getEmployments.pending, (state, action) => {
                state.employment.isLoading = true;
            })
            .addCase(getEmployments.fulfilled, (state, action) => {
                state.employment.totalNum = action.payload.data.length;
                state.employment.list = action.payload.data;
                state.employment.isLoading = false;
            })
            .addCase(getEmployments.rejected, (state, action) => {
                state.employment.isLoading = false;
            })
            .addCase(getEmploymentPerProject.pending, (state, action) => {
                state.employment.isLoading = true;
            })
            .addCase(getEmploymentPerProject.fulfilled, (state, action) => {
                if (action.payload === undefined) {
                    state.employment.totalNum = 0;
                    state.employment.list = [];
                }
                else {
                    state.employment.totalNum = action.payload.data.length;
                    state.employment.list = action.payload.data;
                }
                state.employment.isLoading = false;
            })
            .addCase(getEmploymentPerProject.rejected, (state, action) => {
                state.employment.isLoading = false;
            })
            .addCase(getAttendancePerProject.pending, (state, action) => {
                state.attendance.isLoading = true;
            })
            .addCase(getAttendancePerProject.fulfilled, (state, action) => {
                if (action.payload === undefined) {
                    state.attendance.totalNum = 0;
                    state.attendance.list = [];
                }
                else {
                    state.attendance.totalNum = action.payload.data.length;
                    state.attendance.list = action.payload.data;
                }
                state.attendance.isLoading = false;
            })
            .addCase(getAttendancePerProject.rejected, (state, action) => {
                state.attendance.isLoading = false;
            })
            .addCase(getPayrollPerProject.pending, (state, action) => {
                state.payroll.isLoading = true;
            })
            .addCase(getPayrollPerProject.fulfilled, (state, action) => {
                if (action.payload === undefined) {
                    state.payroll.totalNum = 0;
                    state.payroll.list = [];
                    state.payroll.totalAmount = 0;
                    state.payroll.totalWorker = 0;
                    state.payroll.totalWorkingDays = 0;
                    state.payroll.payrollListID = "";
                }
                else {
                    state.payroll.totalNum = action.payload.data.payrollResult.length;
                    state.payroll.list = action.payload.data.payrollResult;
                    state.payroll.totalAmount = action.payload.data.totalAmount;
                    state.payroll.totalWorker = action.payload.data.totalWorker;
                    state.payroll.totalWorkingDays = action.payload.data.totalWorkingDays;
                    state.payroll.payrollListID = action.payload.data.payrollListID;
                }
                state.payroll.isLoading = false;
            })
            .addCase(getPayrollPerProject.rejected, (state, action) => {
                state.payroll.isLoading = false;
            })
            .addCase(getPayrollHistory.pending, (state, action) => {
                state.payroll.history.isLoading = true;
            })
            .addCase(getPayrollHistory.fulfilled, (state, action) => {
                if (action.payload === undefined) {
                    state.payroll.history.totalNum = 0;
                    state.payroll.history.list = [];
                    state.payroll.history.data = {};
                }
                else {
                    state.payroll.history.totalNum = action.payload.data.length;
                    state.payroll.history.list = action.payload.data;
                    state.payroll.history.data = {};
                }
                state.payroll.history.isLoading = false;
            })
            .addCase(getPayrollHistory.rejected, (state, action) => {
                state.payroll.history.isLoading = false;
            })
            .addCase(getPayrollHistoryByID.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    console.log(action.payload);
                    let data = action.payload.data;
                    state.payroll.history.data[data.payrollID] = data.historyResult;
                }
            })
    }
});

export default workerSlice.reducer