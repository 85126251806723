import { Box } from '@mui/material'
import FormInput from '../../components/input/FormInput';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import LayoutNoTitle from '../../components/core/Layout';

export const Setting = () => {

  const data = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    <LayoutNoTitle>
      <Box sx={{padding: "32px", mr: "32px"}}>
        <FormInput title={t("user.firstname")} fullWidth={true} value={data.firstName} disabled/>
        <FormInput title={t("user.lastname")} fullWidth={true} value={data.lastName} disabled/>
        <FormInput title={t("user.email")} fullWidth={true} value={data.email} disabled/>
        <FormInput title={t("user.role")} fullWidth={true} value={data.roles} disabled/>
        <FormInput title={t("user.company")} fullWidth={true} value={data.company.companyName} disabled/>
        <FormInput title={t("user.companyType")} fullWidth={true} value={data.company.type} disableds/>
      </Box>
    </LayoutNoTitle>
  )
}
