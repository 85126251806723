import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import { Box, MenuItem, Select } from '@mui/material';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 12,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 12,
        padding: '2px 26px 1px 12px',
        height: "24px",
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 12,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

export function HorizontalSelect(props) {
    const theme = useTheme();
    return (
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
            <InputLabel shrink={true} htmlFor="horizontal-customized-textbox" sx={{ minWidth: "100px", fontSize: 22, transformOrigin: "center", color: theme.palette.dark, fontWeight: "600", marginLeft: "-5px" }}>{props.label}</InputLabel>
            <FormControl sx={{ m: 2 }} variant="standard">
                <Select
                    id="horizontal-customized-textbox"
                    value={props.value}
                    onChange={(event) => { props.handleChange(event.target.value) }}
                    sx={{ minWidth: 200 }}
                    input={<BootstrapInput />}
                >
                    {props.rows.map((row, index) => (
                        <MenuItem key={index} value={row.value} sx={{ fontSize: 12 }}>{row.name}</MenuItem>
                    ))};
                </Select>
            </FormControl>
        </Box>
    );
}

export function VerticalSelect(props) {
    return (
        <FormControl sx={{ m: 2, minWidth: 80, ml: -0.1}} fullWidth={true} >
            <InputLabel htmlFor="vertical-customized-textbox">{props.label}</InputLabel>
            <Select
                labelId="vertical-customized-textbox"
                value={props.value}
                onChange={props.handleChange}
                label={props.label}
            >
                {props.rows.map((row, index) => (
                    <MenuItem key={index} value={row["value"]} sx={{ fontSize: 12 }}>{row["name"]}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
