import React, { useEffect } from 'react'
import Navbar from '../../components/navbar/Navbar';
import "./home.scss";
import Box from '@mui/material/Box';
import InfoCard from '../../components/informationcard/InfoCard';
import NotificationBox from '../../components/notificationBox/notificationBox';
import CircularDisplay from '../../components/circularDisplay/CircularDisplay';
import { getMessages } from '../../redux/features/message/messageSlice';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Header from '../../components/header/Header';
import { Paper, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects } from '../../redux/features/project/projectSlice';
import AccountTreeOutlined from '@mui/icons-material/AccountTreeOutlined';
import { useTranslation } from 'react-i18next';
import { INFO_TYPE } from '../../util/util';
import { DangerousOutlined, ReportGmailerrorred, SentimentSatisfiedAltOutlined } from '@mui/icons-material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const Home = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const messages = useSelector((state) => state.message.list);
  const project = useSelector((state) => state.project.data);
  const status = useSelector((state) => state.project.statusForProject);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
    },
    scales: {
      y: {
        ticks: {
          color: theme.palette.primary.text
        }
      },
      x: {
        ticks: {
          color: theme.palette.primary.text
        }
      }
    }
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Data',
        data: [
          2,
          1,
          3,
          4,
          3,
          5,
          2
        ],
        borderColor: theme.palette.red[400],
        backgroundColor: theme.palette.red[400],
      }
    ],
  };


  useEffect(() => {
    dispatch(getMessages());
    dispatch(getProjects());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ display: 'flex' }}>
      <Navbar />
      <Header />
      <Box
        component="main"
        sx={{ flexGrow: 1, mt: 16 }}>
        <Box sx={{ display: 'flex', flexDirection: "row", p: 3, pt: 0 }} >
          <Box sx={{ width: "72%" }}>
            <div className='cardContainer'>
              <InfoCard value={project.length} desc={t('project.totalProjects')} Icon={AccountTreeOutlined} isColor={true} />
              <InfoCard value={status.danger} desc={t('overview.project_in_danger')} Icon={DangerousOutlined} type={INFO_TYPE.DANGER} isColor={true} />
              <InfoCard value={status.warning} desc={t('overview.project_in_warning')} Icon={ReportGmailerrorred} type={INFO_TYPE.WARNING} isColor={true} />
              <InfoCard value={status.normal} desc={t('overview.project_in_healthy')} Icon={SentimentSatisfiedAltOutlined} type={INFO_TYPE.HEALTH} isColor={true} />
            </div>

            <br />

            <div>
              <Paper sx={{ boxShadow: 1, p: 3, borderRadius: 6 }}>
                <div className='subtitle' style={{ color: theme.palette.primary.text, marginBottom: "20px" }} >Project in Danger Status</div>
                <Line options={options} data={data} />
              </Paper>
            </div>

            <br />
            <div className='circleContainer' >
              <div className='circleDisplayContainer'>
                <CircularDisplay value={78} title={t('overview.project_complete_title')}/>
              </div>
              <div className='circleDisplayContainer'>
                <CircularDisplay value={100} title={t('overview.payroll_complete_title')}/>
              </div>
            </div>
          </Box>
          <Box sx={{ width: "28%", backgroundColor: theme.palette.primary.background, ml: 2, mr: 1, borderRadius: 6, maxHeight: 1080, 
          overflow: "hidden",
          overflowY: "scroll",
          '&::-webkit-scrollbar': {
            width: '0.4em',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey',
            borderRadius: 10
          } }}>
            {messages.map((row, index) => {
              return (
                <NotificationBox key={index} id={index} data={row} showButton={true} />
              );
            })}
          </Box>
        </Box>


      </Box>
    </Box>
  )
}
