import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { MyOutlinedButton } from '../button/Button';
import { EnhancedTableHead, getComparator, stableSort, StyledTableCell, StyledTableRow } from './EnhancedTable';
import { useDispatch, useSelector } from 'react-redux';
import { numberToCurrency } from '../../util/util';
import { useNavigate } from 'react-router-dom';
import DatePicker from '../datePicker/DatePicker';
import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import userService from '../../services/user.service';
import InfoCard from '../informationcard/InfoCard';
import { DateRange, Engineering, LocalAtm } from '@mui/icons-material';
import { MyBackdrop } from '../backdrop/Backdrop';

const EnhancedTableToolbar = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const payrollListID = useSelector((state) => state.worker.payroll.payrollListID);
    const payroll = useSelector((state) => state.worker.payroll);

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%", pt: 2
            }}>
                <Stack direction={"row"} spacing={3} sx={{ my: 1 }} alignItems="center" >
                    <DatePicker handleChange={props.setStartDate} inputFormat="yyyy-MM-dd" value={props.startDate} title={t("common.startDate")} />
                    <Typography color={theme.palette.gray[800]}>
                        {t('common.to')}
                    </Typography>
                    <DatePicker handleChange={props.setEndDate} inputFormat="yyyy-MM-dd" value={props.endDate} title={t("common.endDate")} />
                    <MyOutlinedButton name={t('payroll.btnPay')} onClick={() => {
                        userService.createPayroll({ payrollID: payrollListID }).then((result) => {
                            props.setOpen(true);
                        }).catch((error) => {
                            console.log(error);
                            props.setAlertOpen(true);
                        });
                    }}
                        disabled={payrollListID === "" ? true : false}
                    />
                </Stack>
                <Stack alignItems="center" sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}>
                    <MyOutlinedButton name={t('payroll.btnPayHistory')} onClick={() => { navigate("/worker/payroll/history") }} /></Stack>
            </Box>
            <Box sx={{ flexGrow: 1, display: 'flex', mb: 2, width: "100%" }}>
                <InfoCard value={numberToCurrency(payroll.totalAmount)} desc={t('payroll.totalAmount')} Icon={LocalAtm} />
                <InfoCard value={payroll.totalWorker} desc={t('payroll.totalWorker')} Icon={Engineering} />
                <InfoCard value={payroll.totalWorkingDays} desc={t('payroll.totalWorkingDays')} Icon={DateRange} />
            </Box>
        </Toolbar>
    );
};


export default function EnhancedPayrollTable(props) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.worker.payroll.list);
    const isLoading = useSelector((state) => state.worker.payroll.isLoading);

    const headCells = [
        {
            id: 'name',
            align: 'left',
            disablePadding: false,
            label: t("worker.name"),
        },
        {
            id: 'hkid',
            align: 'left',
            disablePadding: false,
            label: t("worker.hkid"),
        },
        {
            id: 'bank',
            align: 'left',
            disablePadding: false,
            label: t("worker.bank"),
        },
        {
            id: 'bankAccount',
            align: 'left',
            disablePadding: false,
            label: t("worker.bankAccount"),
        },
        {
            id: 'totalSalary',
            align: 'left',
            disablePadding: false,
            label: t("payroll.totalSalary"),
        },
        {
            id: 'totalWorkingDays',
            align: 'left',
            disablePadding: false,
            label: t("payroll.totalWorkingDays"),
        }
    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar dispatch={dispatch} startDate={props.startDate} endDate={props.endDate} setStartDate={props.handleStartDateChange} setEndDate={props.handleEndDateChange} setOpen={props.setOpen} setAlertOpen={props.setAlertOpen} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(data, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <StyledTableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <StyledTableCell align="left">{row.name}</StyledTableCell>
                                            <StyledTableCell align="left">{row.hkid}</StyledTableCell>
                                            <StyledTableCell align="left">{row.bank}</StyledTableCell>
                                            <StyledTableCell align="left">{row.bankAccount}</StyledTableCell>
                                            <StyledTableCell align="left">{row.totalSalary}</StyledTableCell>
                                            <StyledTableCell align="left">{row.totalWorkingDays}</StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            {isLoading?<MyBackdrop open={isLoading}><CircularProgress color="inherit" /></MyBackdrop> : data.length === 0?<StyledTableRow>
                                        <StyledTableCell colSpan={9} align="center">{t('common.noTableData')}</StyledTableCell></StyledTableRow>:null}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
