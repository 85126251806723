import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { MyButton, MyOutlinedButton } from '../button/Button';
import { useNavigate } from "react-router-dom";
import { EnhancedTableHead, getComparator, stableSort, StyledTableCell, StyledTableRow } from './EnhancedTable';
import { useDispatch, useSelector } from 'react-redux';
import { formatServerDateTime, INVITATION_STATUS, numberToCurrency } from '../../util/util';
import AlertDialogSlide from '../dialog/AlertDialogSlide';
import userService from '../../services/user.service';
import { getInvitations } from '../../redux/features/invitation/invitationSlice';
import { MyBackdrop } from '../backdrop/Backdrop';
import { CircularProgress } from '@mui/material';

const EnhancedTableToolbar = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();  

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end"
            }}
        >
            <MyOutlinedButton sx={{marginRight: "10px"}} name={t('common.btnRefresh')} onClick={()=>{props.dispatch(getInvitations())}} /> 
            <MyOutlinedButton name={t('invitation.btnNewInvitation')} onClick={()=>{navigate("/invitation/create")}} />
        </Toolbar>
    );
};


export default function EnhancedInvitationTable() {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.invitation.list);
    const user = useSelector((state) => state.user);
    const [open, setOpen] = React.useState(false);
    const [openResult, setOpenResult] = React.useState(false);
    const [selectedProject, setSelectedProject] = React.useState({iid: "", name: "", status: ""});
    const isLoading = useSelector((state) => state.invitation.isLoading);


    const headCells = [
        {
            id: 'createdAt',
            align: 'center',
            disablePadding: false,
            label: t("invitation.date"),
        },
        {
            id: 'project',
            align: 'center',
            disablePadding: false,
            label: t("invitation.project"),
        },
        {
            id: 'company',
            align: 'left',
            disablePadding: false,
            label: t("invitation.company"),
        },
        {
            id: 'category',
            align: 'center',
            disablePadding: false,
            label: t("invitation.category"),
        },
        {
            id: 'budget',
            align: 'center',
            disablePadding: false,
            label: t("invitation.budget"),
        },
        {
            id: 'status',
            align: 'center',
            disablePadding: false,
            label: t("invitation.statusS"),
        },
        {
            id: 'action',
            align: 'center',
            disablePadding: false,
            label: t("invitation.action"),
        },
    ];


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar dispatch={dispatch}/>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(data, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {

                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <StyledTableRow
                                            hover
                                            tabIndex={-1}
                                            key={row._id}
                                        >
                                            <StyledTableCell align="center">{formatServerDateTime(row.createdAt, user.timezone)}</StyledTableCell>
                                            <StyledTableCell align="center">{row.project.name}</StyledTableCell>
                                            <StyledTableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="normal"
                                            >
                                                {row.invitedCompany.companyName}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{t('project.category.'+row.category.key)}</StyledTableCell>
                                            <StyledTableCell align="center">{numberToCurrency(row.budget)}</StyledTableCell>
                                            <StyledTableCell align="center">{t(row.status===INVITATION_STATUS.CREATED?user.company._id===row.company?'invitation.status.waitforReply':'invitation.status.pending':"invitation.status."+row.status)}</StyledTableCell>
                                            {row.status===INVITATION_STATUS.CREATED?user.company._id===row.company?
                                            <StyledTableCell align="center">
                                                <MyButton name={t('common.btnWithdraw')} onClick={()=> {
                                                        setSelectedProject({iid: row._id, name: row.project.name, status: INVITATION_STATUS.CANCELED});
                                                        setOpen(true);
                                                        }}/>
                                            </StyledTableCell>:
                                            <StyledTableCell align="center">
                                                <Box sx={{display: 'flex', flexDirection: "row", justifyContent: "center"}}>
                                                    <MyButton name={t('common.btnAccept')} onClick={()=> {
                                                        setSelectedProject({iid: row._id, name: row.project.name, status: INVITATION_STATUS.ACCEPTED});
                                                        setOpen(true);
                                                        }} />
                                                    <MyButton name={t('common.btnReject')} onClick={()=> {
                                                        setSelectedProject({iid: row._id, name: row.project.name, status: INVITATION_STATUS.REJECTED});
                                                        setOpen(true);
                                                        }} />
                                                </Box>
                                            </StyledTableCell>:<StyledTableCell align="center"></StyledTableCell>
                                            }
                                        </StyledTableRow>
                                    );
                                })}                            
                            {isLoading?<MyBackdrop open={isLoading}><CircularProgress color="inherit" /></MyBackdrop> : data.length === 0?<StyledTableRow>
                                        <StyledTableCell colSpan={7} align="center">{t('common.noTableData')}</StyledTableCell></StyledTableRow>:null}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={7} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            
            <AlertDialogSlide isOpen={open} title={t('invitation.updateInvitationTitle')} text={t(selectedProject.status===INVITATION_STATUS.ACCEPTED?'invitation.acceptInvitation':selectedProject.status===INVITATION_STATUS.REJECTED?'invitation.rejectInvitation':selectedProject.status===INVITATION_STATUS.CANCELED?'invitation.withdrawInvitation':"", {project: selectedProject.name})} 
            handleConfirmClose={()=> {
                setOpen(false);
                userService.updateInvitation(selectedProject).then((result) => {
                if (result.status === 200) {
                    dispatch(getInvitations());
                    setOpenResult(true);
                }
                else {
                    //TODO error page
                }
            })}}
            handleCancelClose={()=> {setOpen(false)}}/>
            <AlertDialogSlide isOpen={openResult} title={t('invitation.updateInvitationTitle')} text={t('invitation.updateInvitationDone')} handleOKClose={()=> {setOpenResult(false)}}/>
        </Box>
    );
}
