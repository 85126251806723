import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import LayoutNoTitle from '../../components/core/Layout';
import PageHeader from '../../components/header/PageHeader';
import { getPayrollPerProject } from '../../redux/features/worker/workerSlice';
import { formatDate } from '../../util/util';
import EnhancedPayrollTable from '../../components/table/PayrollTable';
import AlertDialogSlide from '../../components/dialog/AlertDialogSlide';
import { useNavigate } from 'react-router-dom';

const Payroll = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const selectedProject = useSelector((state) => state.project.selected);
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    const [startDate, setStartDate] = useState(firstDay);
    const [endDate, setEndDate] = useState(lastDay);
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);

    useEffect(() => {
        console.log("Payroll selectedProject: " + selectedProject);
        if (selectedProject!=="") {
            dispatch(getPayrollPerProject({ pid: selectedProject, startDate: formatDate(startDate), endDate: formatDate(endDate)}));
        }
    }, [selectedProject, startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <LayoutNoTitle>
            <Box sx={{pr: 4, pl: 4}}>
                <PageHeader isLess={true} handleChange={() => {}} />
                <EnhancedPayrollTable startDate={startDate} endDate={endDate} handleStartDateChange={setStartDate} handleEndDateChange={setEndDate} setOpen={setOpen} setAlertOpen={setAlertOpen}/>
            </Box>
            <AlertDialogSlide isOpen={open} title={t('page.payrollCreation')} text={t('payroll.dialogCreate')} handleOKClose={()=> {navigate("/worker/payroll/history")}} />
            <AlertDialogSlide isOpen={alertOpen} title={t('page.payrollCreation')} text={t('payroll.invalidPayrollList')} handleOKClose={()=> 
            {
                setAlertOpen(false);             
                if (selectedProject!=="") {
                    dispatch(getPayrollPerProject({ pid: selectedProject, startDate: formatDate(startDate), endDate: formatDate(endDate)}));
                }
            }} />
        </LayoutNoTitle>
    )
}

export default Payroll
