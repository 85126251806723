import { Box, Card, CardContent, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';

import LayoutNoTitle from '../../components/core/Layout';
import { MyOutlinedButton } from '../../components/button/Button';
import userService from '../../services/user.service';
import { handleDownloadFile } from '../../util/util';

const SampleCard = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Card sx={{padding: "16px", paddingBottom: "24px", display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", width: "250px", m:2}}>
      <CardContent sx={{color: theme.palette.primary.main, fontWeight: '600', fontSize: "18px"}}>
        {props.title}
      </CardContent>
      <MyOutlinedButton sx={{marginRight: "10px"}} name={t('common.btnDownload')} onClick={props.onClick} /> 
    </Card>
  );
}

export const SampleFile = () => {
  const { t } = useTranslation();
  return (
    <LayoutNoTitle>
      <Box sx={{padding: "32px", mr: "32px", display: 'flex', flexWrap: 'wrap'}}>
        <SampleCard title={t('page.employmentCreation')} onClick={()=>{
          userService.downloadEmploymentSample().then((response) => {
            handleDownloadFile(response);
          }).catch((error) => {
              console.log(error);
          });
        }}/>
        <SampleCard title={t('page.attendanceCreation')} onClick={()=>{
          userService.downloadAttendanceSample().then((response) => {
            handleDownloadFile(response);
          }).catch((error) => {
              console.log(error);
          });
        }}/>
        <SampleCard title={t('page.applicationCreation')} onClick={()=>{
          userService.downloadApplicationReportSample().then((response) => {
            handleDownloadFile(response);
          }).catch((error) => {
              console.log(error);
          });
        }}/>
      </Box>
    </LayoutNoTitle>
  )
}
