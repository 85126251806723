import { useTheme } from "@mui/material";
import { Box } from "@mui/system";
import BackButton from "../backButton/backButton";
import Header from "../header/Header";
import Navbar from "../navbar/Navbar";

const LayoutWithBackButton = (props) => {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex' }}>
            <Navbar />
            <Header />
            <Box
                component="main"
                sx={{ flexGrow: 1, marginTop: `${theme.header.height}px`, position: "relative", height: `calc(100vh - ${theme.header.height}px)` }}>
                <BackButton name={props.title} /> 
                {props.children}
            </Box>
        </Box>
    );
}

export default LayoutWithBackButton;