import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/system';


const CircularDisplay = (props) => {
  const theme = useTheme();
  let color = theme.palette.red[400];
  if (props.value > 80) {
    color = theme.palette.green[400];
  }
  else if (props.value > 60) {
    color = theme.palette.orange[400];
  }
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', boxShadow: 2, p:2, borderRadius: 2}}>
      <div style={{ color: theme.palette.primary.text, marginBottom: "12px" }}>{props.title}</div>
      <Box sx={{ position: 'relative', display: 'inline-flex', justifyContent: "center"}}>
        <CircularProgress variant="determinate" {...props} size={"8em"} sx={{color: color}} thickness={4}  />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary" style={{fontSize: "24px", color: color}} >
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

CircularDisplay.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CircularDisplay;