import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import { combineReducers } from "redux"
import logger from 'redux-logger'

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import userReducer from './features/user/userSlice'
import projectReducer from './features/project/projectSlice'
import authService from '../services/auth.service'
import companyReducer from './features/company/companySlice'
import invitationReducer from './features/invitation/invitationSlice'
import settingReducer from './features/setting/settingSlice'
import messageReducer from './features/message/messageSlice'
import workerSlice from './features/worker/workerSlice'
import applicationSlice from './features/application/applicationSlice'
import axios from 'axios'

const appReducer = combineReducers({
  user: userReducer,
  project: projectReducer,
  company: companyReducer,
  invitation: invitationReducer,
  setting: settingReducer,
  message: messageReducer,
  worker: workerSlice,
  application: applicationSlice
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
      // for all keys defined in your persistConfig(s)
      storage.removeItem('persist:root')
      // storage.removeItem('persist:otherKey')
      authService.logout();

      const { routing } = state
      state = { routing }

      return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }).concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response?.status === 401) {
    if ( error.response.data?.return_code === 'SESSION_EXPIRED' || error.response.data?.return_code === 'UNAUTHORIZED' ) {
      console.log('session expired')
      store.dispatch({type: 'USER_LOGOUT'})
      return
    }
    //place your reentry code
   }
  return Promise.reject(error);
});