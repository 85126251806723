import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import UserService from "../../../services/user.service";

/*
const INITIAL_STATE = {
    _id: "",
    name: "",
    startDate: "",
    endDate: "",
    price: null,
    manager: "",
    createdDt: ""
}
*/

const INITIAL_STATE = {
    totalNum: 0,
    pageNumber: 0,
    data: [],
    status: {danger: 0, warning: 0, normal: 0},
    statusForProject: {danger: 0, warning: 0, normal: 0},
    totalAmount: 0,
    errorMessage: "",
    tree: {
        list: undefined,
        isLoading: true,
    },
    list: [],
    selected: "",
    isLoading: false,
    cashflow: {
        isLoading: false,
        list: [],
        data: {}
    }
}

export const getProjects = createAsyncThunk(
    "users/projects",
    async (thunkAPI) => {
        try {
            let response = await UserService.getProjects()
            .catch(function (error) {
                return thunkAPI.rejectWithValue("SYSTEM_ERROR");
            });

            return response.data;

        } catch (e) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
);

export const getProjectTree = createAsyncThunk(
    "users/project/tree",
    async ({pid}, thunkAPI) => {
        try {
            let response = await UserService.getProjectTree(pid)
            .catch(function (error) {
                return thunkAPI.rejectWithValue("SYSTEM_ERROR");
            });

            return response.data;

        } catch (e) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
);

export const getCashflow = createAsyncThunk(
    "users/project/cashflow",
    async ({pid}, thunkAPI) => {
        try {
            let response = await UserService.getCashflow(pid)
            .catch(function (error) {
                return thunkAPI.rejectWithValue("SYSTEM_ERROR");
            });

            return response.data;

        } catch (e) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
);

export const getPendingPayrollPerProject = createAsyncThunk(
    "users/project/payroll/project/:pid",
    async ({pid}, thunkAPI) => {
        try {
            let response = await UserService.getPendingPayrollPerProject(pid)
                .catch(function (error) {
                    console.log(error);
                    return thunkAPI.rejectWithValue("SYSTEM_ERROR");
                });

            return response.data;

        } catch (e) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
);

export const projectSlice = createSlice({
    name: 'project',
    initialState: INITIAL_STATE,
    reducers: {
        setSelectedProject: (state, action) => {
            state.selected = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProjects.pending, (state, action) => {
            })
            .addCase(getProjects.fulfilled, (state, action) => {
                state.totalNum = action.payload.data.company.projects?.length;
                state.data = action.payload.data.company.projects;
                state.status = action.payload.data.overallStatus;
                state.statusForProject = action.payload.data.overallProjectStatus;
                state.totalAmount = action.payload.data.totalAmount;
                state.list = [];
                action.payload.data.company.projects.map((data) => {
                    return state.list.push({...data, value: data._id, company: {companyName: action.payload.data.company.companyName}});
                });
            })
            .addCase(getProjects.rejected, (state, action) => {
                state = {
                    ...INITIAL_STATE,
                    errorMessage: "System Error"
                }
            })
            .addCase(getProjectTree.pending, (state, action) => {
                state.tree = {list: undefined, isLoading: true};
            })
            .addCase(getProjectTree.fulfilled, (state, action) => {
                state.tree.list = action.payload.data;
                state.tree.isLoading = false;
            })
            .addCase(getProjectTree.rejected, (state, action) => {
                state.tree.list = undefined;
                state.errorMessage = "System Error";
                state.tree.isLoading = false;
            })
            .addCase(getCashflow.pending, (state, action) => {
                state.cashflow.list = [];
                state.cashflow.isLoading = true;
            })
            .addCase(getCashflow.fulfilled, (state, action) => {
                state.cashflow.list = [action.payload.data];
                state.cashflow.isLoading = false;
            })
            .addCase(getCashflow.rejected, (state, action) => {
                state.cashflow.list = [];
                state.cashflow.isLoading = false;
            })
            .addCase(getPendingPayrollPerProject.pending, (state, action) => {
            })
            .addCase(getPendingPayrollPerProject.fulfilled, (state, action) => {
                if (action.payload !== undefined && action.payload.data.payrollResult.length > 0) {
                    console.log(action.payload);
                    let data = action.payload.data;
                    state.cashflow.data[data.id] = data.payrollResult;
                }
            })
            .addCase(getPendingPayrollPerProject.rejected, (state, action) => {
            })
        }
});

export default projectSlice.reducer;
export const { setSelectedProject } = projectSlice.actions;