import React, { useState } from 'react'
import "./login.scss";
import { styled, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextInput from '../../components/input/TextInput';
import { MyBigButton } from '../../components/button/Button';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/features/user/userSlice';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#f0f8ff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Banner = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    ...theme.typography.body2,
    padding: { sm: 50 },
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const Login = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/home";
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const isError = useSelector((state) => state.user.isError);

    const dispatch = useDispatch();
    
    return (
        <Grid container>
            <Grid item xs={0} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                <Banner sx={{display: "flex", flexDirection: "column"}}>
                    <img alt="Logo" src="/images/login.svg" style={{ height: '40vh', marginTop: "-8vh" }} />
                    <Grid item xs={12} sx={{ color: 'white', fontSize: '30px', marginTop: "5vh", pl: "10vw", pr: "10vw" }}>
                        Construction Blockchain Contract & Payroll System
                    </Grid>
                    
                    <Grid item xs={12} sx={{ color: 'white', fontSize: '20px', marginTop: "3vh", pl: "10vw", pr: "10vw" }}>
                        #Web3.0 #Defi #blockchain #payroll #dAppp
                    </Grid>
                </Banner>
            </Grid>
            <Grid item xs={12} md={6}>
                <Item>
                    <Grid container direction="column" spacing={1}>
                        <Grid item xs={12} sx={{ fontSize: 32, color: theme.palette.primary.main, fontWeight: '600', marginBottom: 0, marginTop: -5 }}>Welcome back DefyPay!</Grid>
                        <Grid item xs={12} sx={{ fontSize: 20, color: theme.palette.primary.main, fontWeight: '500', marginBottom: 2 }}>Sign in your account</Grid>
                        <Grid item xs={12}>
                            <TextInput
                                required
                                id="email"
                                label={t('login.email')}
                                variant="standard"
                                placeholder={t('login.email')}
                                onChange={(e)=>{setEmail(e.target.value)}}
                                value={email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                required
                                id="password"
                                type="password"
                                autoComplete="current-password"
                                variant="standard"
                                label={t('login.password')}
                                placeholder={t('login.password')}
                                onChange={(e)=>{setPassword(e.target.value)}}
                                value={password}
                            />
                        </Grid>
                        {isError?
                        <Grid item xs={12} sx={{ fontSize: 14, color: theme.palette.red[600], fontWeight: '500', marginBottom: 2 }}>Invalid email or password</Grid>:null}
                        <Grid item xs={12}>
                            <MyBigButton sx={{marginTop: 1}} name={t('loginBtn')} onClick={
                                () => {
                                    dispatch(loginUser({email, password})).then((data)=>{
                                        if (!data.error) navigate(from, {replace: true})
                                    })
                                }}/>
                        </Grid>
                    </Grid>
                </Item>
            </Grid>
        </Grid>
    )
}
